import React from 'react';
import { Modal, Typography, Box, Button } from '@mui/material';
import cetncontent from './../../assets/cetncontent.jpg';
import useTexts from "../../hooks/useTexte";
import {style} from "../../Utils/style";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel"; // Tell webpack this JS file uses this image


const ConfirmeModal = ({ open, message, onClose, onConfirm}) => {
    const {t} = useTexts()

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "60%",
                // maxWidth: '600px', // Optionnel : Limite la largeur maximale
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                textAlign: 'center'
            }}>
                <Typography id="modal-description" sx={{ mt: 2 }}>
                    {message}
                </Typography>
                <Box sx={{mt: 2}}>
                    <button onClick={onClose} style={{
                        color: style.red,
                        border: `1px solid ${style.red}`,
                        padding: '10px 8px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        borderRadius: '5px'
                    }}><CancelIcon color={style.red}/> {t("BUTTON_ANNULER")}</button>
                    <button type="submit" onClick={onConfirm} style={{
                        color: style.bleu1,
                        border: `1px solid ${style.bleu1}`,
                        padding: '10px 8px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        borderRadius: '5px'
                    }}><AddIcon color={style.bleu1}/> {t("BUTTON_VALIDATE")}</button>

                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmeModal;
