import React, { useEffect, useState } from 'react';
import {
    Grid, Box, CircularProgress
} from '@mui/material';

import FormationItem from '../composant/Formation/FormationItem';
import { useAuth } from '../Context/AuthContext';
import { branchOptions } from '../Utils/BranchOption';
import { useFetchWithToken } from "../hooks/useFetchWithToken";
import useTexts from "../hooks/useTexte";
import NoResultFound from "../composant/NoResultFound";

const FormationListPage = () => {
    const { isLoadingFunction, functionOptions, section, currentFunction } = useAuth();
    const [open, setOpen] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [selectedBranche, setSelectedBranche] = useState('');
    const fetchWithToken = useFetchWithToken();
    const { t } = useTexts()

    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
    };

    const handleChangeBranche = (event) => {
        setSelectedBranche(event.target.value);
    };

    const loadWishes = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/myfomrations`;
            const data = await fetchWithToken(apiUrl);
            setWishes(data);
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    useEffect(() => {

        loadWishes();
    }, [currentFunction])

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [wishes, setWishes] = useState([]);

    if (!isLoadingFunction) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
        </Box>;
    }

    const filteredWishes = wishes.filter(wish => {
        // Ici, vous pouvez afficher les détails du vœu actuel
        const matchesType = selectedType ? wish.level === selectedType : true;
        const matchesBranche = selectedBranche ? wish.branch === selectedBranche : true;
        return matchesType && matchesBranche;
    });

    const filterbranchOptions = branchOptions.filter(branchOption => {
        return branchOption.section == section || branchOption.section == "mixte";
    });

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={3}>

            {filteredWishes.length > 0 ? (
                    filteredWishes.map((wish, index) => (
                        <FormationItem formation={wish} index={index} />
                    ))
                ) : (
                    <NoResultFound text={t("NO_FORMATION")} />
                )}
            </Grid>
        </Box>
    );
};

export default FormationListPage;
