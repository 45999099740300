import React, {useEffect, useState} from 'react';
import {

    Grid,
    Container, Box, Typography,

} from '@mui/material';


import {useAuth} from '../Context/AuthContext';

import {useSystem} from "../Context/System";
import FormationMangerItem from "../composant/Formation/FormationManagerItem";
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import useTexts from "../hooks/useTexte";

const FormationManagerListPage = () => {
    const {isLoadingFunction, functionOptions, section} = useAuth();

    const {myFormationAsChef} = useSystem()
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts()


    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>

            <Grid container spacing={2}>
                    {myFormationAsChef.map((formation, index) => (
                        <FormationMangerItem key={index} formation={formation} index={index}/>
                    ))}
            </Grid>
        </Box>
    );
};

export default FormationManagerListPage;
