import React, {useEffect, useState} from 'react';
import {
    Button,
    Grid,
    Container,
    Toolbar,
    AppBar,
    Box,
    CircularProgress,
    TextField,
    CardContent,
    Card, DialogTitle, DialogContent, Dialog, Typography, IconButton
} from '@mui/material';
import {useFetchWithToken} from '../hooks/useFetchWithToken';
import {branchOptions} from '../Utils/BranchOption';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useTexts from "../hooks/useTexte";

import {useAuth} from "../Context/AuthContext";
import withAdminRole from "../hoc/withAdminRole";

import SearchModal from "../composant/Search/SearchModal";
import UserFunction from "../composant/Search/UserFunction";
import StructureAutocomplete from "../composant/StructureAutocomplete";
import {useSystem} from "../Context/System";
import UserFormations from "../composant/Search/UserFormations";
import {formatErrors} from "../Utils/formatErrors";
import UserCamp from "../composant/Search/UserCamp";
import withAdminOrFormationRole from "../hoc/withAdminOrFormationRole";
import {formatDate} from "../Utils/functionTransforms";

const UserListPage = ({codeSycomoreData}) => {

    const [selectedPersonne, setSelectedPersonne] = useState(false);
    const [dialogOpenSearch, setDialogOpenSearch] = useState(false);
    const [functions, setFunctions] = useState([]);
    const [formations, setFormations] = useState([]);

    const [camps, setCamps] = useState([]);
    const [birthday, setBirthday] = useState("");

    const [email, setEmail] = useState("");
    const [codeSycomore, setCodeSycomore] = useState("");
    const [page, setPage] = useState(1);
    const [isFunctionsEmpty, setIsFunctionsEmpty] = useState(false); // Pour gérer l'état de fonctions vides
    const fetchWithToken = useFetchWithToken();
    const {t} = useTexts();
    const {isLoadingFunction, section} = useAuth();
    const [logPage, setLogPage] = useState(1); // Pagination des logs utilisateur
    const [logs, setLogs] = useState([]); // Logs utilisateur
    const [totalLogPages, setTotalLogPages] = useState(1); // Total des pages de logs
    const [dialogOpenLogs, setDialogOpenLogs] = useState(false); // Pour le journal utilisateur


    const loadUserFunction = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/user`;
            const body = {
                user: (selectedPersonne ? selectedPersonne.id : null),
                codeSycomoreData: codeSycomoreData,
                page
            };
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setFunctions(data.functions);
            setFormations(data.formation);
            setCamps(data.camps);
            setCodeSycomore(data.user.code_sycomore);
            setEmail(data.user.email);
            setBirthday(data.user.birthday);
            setCodeSycomore(data.functions[0].code_sycomore); // Récupérer l'email à partir de la première fonction
            if (data.functions && data.functions.length > 0) {
                setIsFunctionsEmpty(false); // Si des fonctions sont trouvées, fonctions non vides
            } else {
                setIsFunctionsEmpty(true); // Si la liste est vide, définir à true
            }

        } catch (error) {
            setFunctions([]);
            setFormations([]);
            setCamps([]);
            setCodeSycomore("");
            setEmail("");
            setBirthday("");
            setCodeSycomore(""); // Récupérer l'email à partir de la première fonction
            console.error('Erreur lors du chargement des fonctions:', error);
        }
    };

    useEffect(() => {
        if (selectedPersonne || codeSycomoreData) {
            loadUserFunction();
        } else {
            setIsFunctionsEmpty(false); // Si la liste est vide, définir à true
            setEmail(""); // Récupérer l'email à partir de la première fonction

        }
    }, [selectedPersonne, codeSycomoreData]);


    const HandlePersonneSearch = (newuser) => {
        setSelectedPersonne(newuser);
        setDialogOpenSearch(false);
    };

    const nextPage = () => {
        setPage(page + 1);
    };

    const handleResetPersonne = () => {
        setSelectedPersonne(null);
        setDialogOpenSearch(false);
    };

    const prevPage = () => {
        if (page > 1) setPage(page - 1);
    };
    useEffect(() => {
        if (dialogOpenLogs) {
            loadUserLogs();
        }
    }, [dialogOpenLogs, logPage]);
    const loadUserLogs = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/user/log?page=${logPage}`;
            const body = { user: selectedPersonne.id };
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setLogs(data.logs);
            setTotalLogPages(data.totalPages);
        } catch (error) {
            console.error('Erreur lors du chargement des logs:', error);
        }
    };

    const handleOpenSearchDialog = () => {
        setDialogOpenSearch(true);
    };

    const formatSearchUser = (user) => {
        return user.firstname + " " + user.lastname;
    };

    if (!isLoadingFunction) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
                <CircularProgress/>
            </Box>
        );
    }
    const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();

        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
        ) {
            age--;
        }

        return age;
    };

    const filterbranchOptions = branchOptions.filter(branchOption => {
        return branchOption.section === section || branchOption.section === "mixte";
    });

    return (
        <Container maxWidth="xl">
            {!codeSycomoreData && (
                <>

                    <AppBar position="static" color="default" sx={{top: 10, boxShadow: "none", background: "none"}}>
                        <Toolbar>
                            <Container maxWidth="xl"
                                       sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2}}>

                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2}>
                                        <TextField
                                            label="Nom de l'utilisateur"
                                            value={(selectedPersonne ? formatSearchUser(selectedPersonne) : "")}
                                            InputProps={{readOnly: true}}
                                            onClick={handleOpenSearchDialog}
                                            variant="outlined"
                                            sx={{flex: 1}}
                                        />
                                    </Box>
                                </Grid>


                            </Container>
                        </Toolbar>
                    </AppBar>

                    <Dialog open={dialogOpenSearch} onClose={() => setDialogOpenSearch(false)}
                            aria-labelledby="search-dialog-title">
                        <DialogContent>
                            <SearchModal onClick={HandlePersonneSearch} functionMode={0}/>
                            <Button variant="contained" color="secondary" onClick={handleResetPersonne}>
                                Réinitialiser
                            </Button>
                        </DialogContent>
                    </Dialog>

                    <Toolbar/>
                </>
            )}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* Affichage du message en rouge et clignotant si fonctions est vide */}
                    {isFunctionsEmpty && (
                        <Typography sx={{color: 'red', animation: 'blink 1s step-end infinite'}}>
                            Pas de fonction
                        </Typography>
                    )}

                    {/* Affichage de l'email récupéré */}
                    {email && (
                        <Typography variant="h6" sx={{marginBottom: 2}}>
                            Email de l'utilisateur : {email}
                        </Typography>
                    )}
                    {codeSycomore && (
                        <Typography variant="h6" sx={{marginBottom: 2}}>
                            code sycomore de l'utilisateur : {codeSycomore}
                        </Typography>
                    )}
                    {birthday && (
                        <Typography variant="h6" sx={{marginBottom: 2}}>
                            age : {calculateAge(birthday)}
                        </Typography>
                    )}

                    {birthday && (
                        <Typography variant="h6" sx={{marginBottom: 2}}>
                            Date de naissance : {formatDate(birthday)}
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await loadUserLogs();
                            setDialogOpenLogs(true); // Ouvrir la modal après le chargement des logs
                        }}
                        sx={{ marginBottom: 2 }}
                    >
                        Voir journal utilisateur
                    </Button>
                    {functions.map((userf) => (
                        <Card sx={{width: '100%', marginBottom: 2}} key={userf.id_function}>
                            <CardContent sx={{
                                display: 'flex',
                                flexDirection: {xs: 'column', sm: 'row'},
                                alignItems: 'center',
                                gap: 2
                            }}>
                                <UserFunction u={userf} loadChef={loadUserFunction} isPage={!codeSycomoreData}/>

                            </CardContent>
                        </Card>
                    ))}
                    <Typography variant="h6" sx={{marginBottom: 2}}>
                        Formations du chef
                    </Typography>
                    <Grid container spacing={1}>

                        {formations.map((userf) => (
                            <Grid item xs={12} md={6} lg={4} key={userf.id}>
                                <CardContent sx={{
                                    display: 'flex',
                                    flexDirection: {xs: 'column', sm: 'row'},
                                    alignItems: 'center',
                                    gap: 2
                                }}>
                                    <UserFormations f={userf}/>

                                </CardContent>
                            </Grid>
                        ))}
                    </Grid>

                    <Typography variant="h6" sx={{marginBottom: 2}}>
                        Camp effectué par le chef
                    </Typography>
                    <Grid container spacing={1}>

                        {camps.length > 0 && camps.map((userf) => (
                            <Grid item xs={12} md={6} lg={4} key={userf.id}>
                                <CardContent sx={{
                                    display: 'flex',
                                    flexDirection: {xs: 'column', sm: 'row'},
                                    alignItems: 'center',
                                    gap: 2
                                }}>
                                    <UserCamp f={userf}/>

                                </CardContent>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={dialogOpenLogs} onClose={() => setDialogOpenLogs(false)} fullWidth maxWidth="md">
                <DialogTitle>Journal de l'utilisateur</DialogTitle>
                <DialogContent>
                    {logs.length > 0 ? (
                        <>
                            <Grid container spacing={2}>
                                {logs.map((log) => (
                                    <Grid item xs={12} key={log.id}>
                                        <Card>
                                            <CardContent>
                                                <Typography>
                                                    {log.event_code} - {log.date_add} - {log.user_lastname} {log.user_firstname}
                                                </Typography>

                                                {/* Si writer_id est différent de user_id, afficher les informations de l'auteur */}
                                                {log.writer_id !== log.user_id && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        Écrit par {log.writer_lastname} {log.writer_firstname} dans {log.structure_name}
                                                    </Typography>
                                                )}

                                                {/* Gestion des différents codes d'événements */}
                                                {log.event_code === 'RefuseFormation' && (
                                                    <Typography>
                                                        Formation refusée : {log.data.formationNom} <br/>
                                                        Date de la formation : {log.data.requestDate} <br/>
                                                        Type de formation : {log.data.requestLevel} <br/>
                                                        Branche : {log.data.requestBranch}
                                                    </Typography>
                                                )}

                                                {log.event_code === 'validateFormation' && (
                                                    <Typography>
                                                        Formation validée : {log.data.formationNom} <br/>
                                                        Date de la formation : {log.data.requestDate} <br/>
                                                        Type de formation : {log.data.requestLevel} <br/>
                                                        Branche : {log.data.requestBranch}
                                                    </Typography>
                                                )}

                                                {log.event_code === 'AddWish' && (
                                                    <Typography>
                                                        Ajout d'un voeu : <br/>
                                                        Branche : {log.data.requestBranch} <br/>
                                                        Date : {log.data.requestDate} <br/>
                                                        Niveau : {log.data.requestLevel} <br/>
                                                        Spécialité : {log.data.requestSpeciality.length > 0 && (
                                                        <ul>
                                                            {log.data.requestSpeciality.map((speciality, index) => (
                                                                <li key={index}>
                                                                    {speciality === 'nospecialite' ? 'no_preference' : speciality}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    </Typography>
                                                )}

                                                {log.event_code === 'removeWish' && (
                                                    <Typography>
                                                        Suppression d'un voeu : <br/>
                                                        Date : {log.data.requestDate} <br/>
                                                        Niveau : {log.data.requestLevel} <br/>
                                                        Spécialité : {log.data.requestSpeciality.length > 0 && (
                                                        <ul>
                                                            {log.data.requestSpeciality.map((speciality, index) => (
                                                                <li key={index}>
                                                                    {speciality.type === 'nospecialite' ? 'no_preference' : speciality.type}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                        Formations : {log.data.requestFormation.length > 0 ? (
                                                        <ul>
                                                            {log.data.requestFormation.map((formation, index) => (
                                                                <li key={index}>
                                                                    Formation ID : {formation.id_formation}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : 'Aucune formation sélectionnée'}
                                                    </Typography>
                                                )}
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                ))}
                            </Grid>
                            <Box display="flex" justifyContent="space-between" marginTop={2}>
                                <IconButton
                                    onClick={() => setLogPage((prev) => Math.max(prev - 1, 1))}
                                    disabled={logPage === 1}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                <Typography>Page {logPage} sur {totalLogPages}</Typography>
                                <IconButton
                                    onClick={() => setLogPage((prev) => (prev < totalLogPages ? prev + 1 : prev))}
                                    disabled={logPage === totalLogPages}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Box>
                        </>
                    ) : (
                        <Typography>Aucun log trouvé.</Typography>
                    )}
                </DialogContent>
            </Dialog>

        </Container>
    );
};

export default withAdminOrFormationRole(UserListPage);
