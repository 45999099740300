import React from 'react';
import {Typography, Box, Container, Grid, Button} from '@mui/material';
import {urlFor} from '../sanityClient';
import BannerImage from "./BannerImage";
import {PortableText} from '@portabletext/react';
import ProgressionBlock from "./ProgressionBlock";
import ColBlock from "./ColBlock";

const ContentBlockRenderer = ({block}) => {
    switch (block._type) {
        case 'block':
            switch (block.style) {
                case 'h1':
                    return <Typography variant="h3" component="h1" gutterBottom>{block.children[0].text}</Typography>;
                case 'h2':
                    return <Typography variant="h4" component="h2" gutterBottom>{block.children[0].text}</Typography>;
                case 'h3':
                    return <Typography variant="h5" component="h3" gutterBottom>{block.children[0].text}</Typography>;
                case 'h4':
                    return <Typography variant="h6" component="h4" gutterBottom>{block.children[0].text}</Typography>;
                case 'normal':
                default:
                    return <Typography variant="body1" component="p" gutterBottom>{block.children[0].text}</Typography>;
            }
        case 'centeredText':
            return <Typography variant="h6" component="div" align="center">{block.text}</Typography>;
        case 'titleBlock':
            return <Typography variant="h4" component="div">{block.text}</Typography>;
        // case 'image':
        //     return <img src={urlFor(block.asset).url()} alt="content" style={{ maxWidth: '100%' }} />;
        case 'textBlock':
            return <Typography variant="body1" component="p" gutterBottom> <PortableText
                value={block.text}/></Typography>;
        case 'textImageBlock':
            const isLeftPosition = block.position === 'left';

            return (
                <Box sx={{my: {xs: 7, md: 12}}}>
                    <Container>
                        <Grid container spacing={4} alignItems="center"
                              direction={isLeftPosition ? 'row' : 'row-reverse'}>
                            <Grid item xs={12} lg={6}>
                                {block.image?.asset && (
                                    <Box component="figure" role="group" sx={{my: 0}}>
                                        <img src={urlFor(block.image.asset).url()} alt="content"
                                             style={{width: '100%'}}/>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <h2>
                                    {block.title}
                                </h2>
                                <p>
                                    <PortableText value={block.text}/>
                                </p>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            );
        case 'bannerImage':
            return <BannerImage image={block.image}/>;
        case 'progressionBlock':
            return (
                <ProgressionBlock
                    title={block.title}
                    textColor={block.textColor}
                    backgroundColor={block.backgroundColor}
                    items={block.items}
                />
            );
        case 'ColBlock':
            return (
                <ColBlock
                    title={block.title}
                    textColor={block.textColor}
                    backgroundColor={block.backgroundColor}
                    lines={block.lines}
                />
            );

        default:
            return null;
    }
};

export default ContentBlockRenderer;
