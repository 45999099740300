import React, {useState} from 'react';
import {useAuth} from "../../Context/AuthContext";
import {useNavigate} from 'react-router-dom';
import {transformFunctionOption} from "../../Utils/functionTransforms";
import {useSystem} from "../../Context/System";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import './styles/HeaderProfile.css';
import {Menu, MenuItem, Select} from '@mui/material';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LogoutIcon from '@mui/icons-material/Logout';

function HeaderProfile() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const {
        token,
        logout,
        currentFunction,
        functionOptions,
        setcurrentFUnctionHanlder,
        isLoadingFunction,
        runAsStart
    } = useAuth();
    const [displayPopup, setDisplayPopup] = useState(false);
    const {userData} = useSystem();
    const navigate = useNavigate();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);


    if (runAsStart === true) {
        return <></>
    }
    if (!chefFunction && functionOptions.length && runAsStart != true) {
        logout(); // Appeler la fonction de logout
        navigate('/login', {state: null}); // Rediriger en purgant location.state
    }

    const changeFunction = (e) => {
        setcurrentFUnctionHanlder(e);
    };

    const handlePostback = (actionName) => {
        // Implémentez la logique spécifique pour les postbacks ici
    };

    const handleLogout = () => {

        logout(); // Appeler la fonction de logout
        navigate('/login', {state: null}); // Rediriger en purgant location.state
    };

    const handleMenu = (event) => {
        if (isMenuOpen)
            setAnchorEl(null);
        else
            setAnchorEl(event.currentTarget);
        setIsMenuOpen(!isMenuOpen);
    };

    if (!chefFunction) {
        return <></>;
    }

    return (
        <div className='headerProfile-container'>
            <div className='headerProfile-name' onClick={handleMenu}>
                {userData.firstname} {userData.lastname}
                <div id="_divLoginRole" className="row profil-login-role">
                    {transformFunctionOption(chefFunction)}
                </div>
                {isMenuOpen ?
                    <ArrowUpIcon className='headerProfile-arrowUp'/>
                    :
                    <ArrowDownIcon className='headerProfile-arrowDown'/>
                }
            </div>
            <div className='headerMenu-responsive'>
                <AccountCircleRoundedIcon onClick={handleMenu}/>
            </div>
            {isMenuOpen && (
                <Menu
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={handleMenu}
                >
                    <MenuItem>
                        <Select
                            value={currentFunction}
                            onChange={(event) => changeFunction(event.target.value)}
                            displayEmpty
                            fullWidth
                        >
                            {functionOptions.map(({fonction, id, nom, type}) => (
                                <MenuItem key={id} value={id}>
                                    {nom} - {fonction}
                                </MenuItem>
                            ))}
                        </Select>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                        <LogoutIcon/>
                        <span className="text-profil-link">Déconnexion</span>
                    </MenuItem>
                </Menu>
            )}
        </div>
    );

}

export default HeaderProfile;
