import React, { useEffect, useState, createContext, useContext } from 'react';
import { useSystem } from "../Context/System";

const FreshdeskWidgetContext = createContext();

export const useFreshdeskWidget = () => {
    return useContext(FreshdeskWidgetContext);
};

const FreshdeskWidgetProvider = ({ children }) => {
    const { getConfiguration } = useSystem();
    const freshdesk_id = getConfiguration('FRESHDESK_ID');
    const freshdesk_url = getConfiguration('FRESHDESK_URL');
    const [isWidgetInitialized, setIsWidgetInitialized] = useState(false);

    useEffect(() => {
        if (freshdesk_url && !isWidgetInitialized) {
            window.fwSettings = {
                'widget_id': freshdesk_id
            };

            if (typeof window.FreshworksWidget === "function") {
                window.FreshworksWidget("hide");
            } else {
                var n = function () {
                    n.q.push(arguments);
                };
                n.q = [];
                window.FreshworksWidget = n;
            }

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = freshdesk_url;

            document.head.appendChild(script);
            setIsWidgetInitialized(true);

            return () => {
                document.head.removeChild(script);
            };
        }
    }, [freshdesk_url, isWidgetInitialized]);

    const openWidget = () => {
        if (typeof window.FreshworksWidget === "function") {
            window.FreshworksWidget("open");
        }
    };

    return (
        <FreshdeskWidgetContext.Provider value={{ openWidget }}>
            {children}
        </FreshdeskWidgetContext.Provider>
    );
};

export default FreshdeskWidgetProvider;
