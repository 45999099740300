import React from 'react';
import { Card, CardContent, Typography, Divider, List, ListItemText, ListItemButton } from '@mui/material';
import ClickIcon from '@mui/icons-material/TouchApp'; // Assurez-vous d'avoir importé cette icône depuis @mui/icons-material

const SearchResult = ({ person, index, handleClick, functionMode }) => {
    return (
        <>
            <Card
                key={index}
                elevation={3}
                style={{ minWidth: 275, cursor: 'pointer', transition: 'transform 0.2s' }}
                onClick={(e) => {
                    if (handleClick && !functionMode) { handleClick(person) };
                }}
                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
                <CardContent>
                    <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center' }}>
                        <ClickIcon style={{ marginRight: 8 }} />
                        {person.lastname} - {person.firstname}
                    </Typography>

                    <Divider style={{ margin: '10px 0' }} />
                    <Typography variant="body1">
                        Code: {person.code_sycomore}
                    </Typography>
                    {functionMode === 1 && person.fonctions.length > 0 && (
                        <List>
                            {person.fonctions.map((fonction, index) => (
                                <ListItemButton key={index} onClick={() => handleClick(person, fonction.id)}>
                                    <ListItemText primary={`${fonction.fonction} - ${fonction.structure_name}`} />
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default SearchResult;
