import { useMemo } from 'react';
import {useSystem} from "../Context/System";
import {useAuth} from "../Context/AuthContext";


const useRoles = (roleKey) => {
    const { getConfiguration } = useSystem();
    const  {currentFunction} = useAuth();
    const list_admin = getConfiguration('ADMIN_ROLES');
    const list_resp_formation = getConfiguration('ROLE_RESP_FORMATION');
    // Transform list_admin into an array
    const adminRoles = useMemo(() => {
        return list_admin ? list_admin.split(',') : [];
    }, [list_admin, currentFunction]);

    const formation_resp_Roles = useMemo(() => {
        return list_resp_formation ? list_resp_formation.split(',') : [];
    }, [list_resp_formation, currentFunction]);
    const roles = useMemo(() => {
        // Check if roleKey is in adminRoles
        if (adminRoles.includes(roleKey)) {
            return ['Administrator'];
        }
        if (formation_resp_Roles.includes(roleKey)) {
            return ['Responsable_formation','User'];
        }
        // Return default roles if roleKey is not in adminRoles
        return 'User';
    }, [roleKey, adminRoles, currentFunction]);

    return roles;
};

export default useRoles;