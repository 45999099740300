import React from 'react';
import { Redirect } from 'react-router-dom';
import { Typography } from '@mui/material';
import useRoles from '../hooks/useRoles'; // Assurez-vous que le chemin d'importation est correct
import { useAuth } from "../Context/AuthContext";
import useTexte from "../hooks/useTexte";

const withAdminRole = (WrappedComponent) => {
    return (props) => {
        const { currentFunction, functionOptions } = useAuth();
        const chefFunction = functionOptions.find(func => func.id == currentFunction);
        const roles = useRoles(chefFunction ? chefFunction.fonction : "");
        const {t} = useTexte();

        // Vérifiez si l'utilisateur a le rôle d'administrateur
        if (!roles.includes('Administrator') && !roles.includes('Responsable_formation') ) {
            return <Typography variant="h6">{t("ACCES_INTERDIT")}</Typography>;
        }

        // Si l'utilisateur a le rôle d'administrateur, rendre le composant enveloppé
        return <WrappedComponent {...props} />;
    };
};

export default withAdminRole;
