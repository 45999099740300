// BannerImage.js
import React from 'react';
import { Box } from '@mui/material';
import { urlFor } from '../sanityClient';

const BannerImage = ({ image }) => {
    if (!image || !image.asset) {
        return null;
    }

    return (
        <Box mb={2} sx={{ width: '100%', overflow: 'hidden' }}>
            <img
                src={urlFor(image.asset).url()}
                alt={image.alt || 'Banner'}
                style={{
                    width: '100%',
                    height: '300px',
                    objectFit: 'cover',
                    aspectRatio: '16/9', // Aspect ratio for banner
                }}
            />
        </Box>
    );
};

export default BannerImage;
