import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, Container, Grid, Typography, CircularProgress} from '@mui/material';
import client, {urlFor} from '../sanityClient';
import ContentBlockRenderer from '../composant/ContentBlockRenderer';
import MarketingBlock from '../composant/Core/MarketingBlock';
import { PortableText } from '@portabletext/react';

function HomeAdminPage() {

    return (
       <>xx</>
    )
        ;
}

export default HomeAdminPage;
