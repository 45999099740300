import logoloup from './../assets/loup.png';
import logofeu from './../assets/feu.png';
import logoecl from '../assets/ECLAIREURS.png';
import logoroute from './../assets/route.png';
import logoguide from '../assets/ECLAIREUSES.png';
import logoterr from './../assets/senamco.png';


export const branchOptions = [
    {
        key: 'louvetisme', label: 'Louvetisme', section: 'mixte', id: 1, logo: logoloup, 'choicecep': 0,
    },
    {key: 'eclaireur', label: 'Éclaireurs', section: 'scout', id: 2, logo: logoecl, choicecep: 1,},
    {key: 'route', label: 'Route', section: 'scout', id: 5, logo: logoroute, choicecep: 0,},
    {key: 'eclaireuse', label: 'Éclaireuses', section: 'guide', id: 3, logo: logoguide, choicecep: 1,},
    {key: "feu", label: "Feu", section: 'guide', id: 4, logo: logofeu, 'choicecep': 0},
    {key: "territorial", label: "Territoriale", section: 'mixte', id: 6, logo: logoterr, choicecep: 0},
];

