import React from 'react';
import {useLocation} from 'react-router-dom';

function ErrorPage() {
    const location = useLocation();
    const { message } = location.state || { message: 'Une erreur est survenue.' };

    return (
        <div>
            <h1>Erreur</h1>
            <p>{message}</p>
        </div>
    );
}

export default ErrorPage;
