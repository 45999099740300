import React, {useEffect, useState} from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {CssBaseline,} from '@mui/material';
import {AuthProvider, useAuth} from './Context/AuthContext';
import {SearchProvider} from './Context/SearchContext';
import {SystemProvider, useSystem} from './Context/System';




// <link href="../../App_Themes/AGSE/styles/variables.css" type="text/css" rel="stylesheet" /><title>


import './styles/1-bootstrap.min.css';
import './styles/2-bootstrap-datetimepicker.min.css';

import './styles/2-bootstrap-submenu.min.css';
// import './styles/2-fontello.css';
import './styles/3-tablesaw.css';
import './styles/BasePagePublicStyle.css';
import './styles/default.css';
import './styles/parscout.css';
import './styles/global.css';

import './styles/stylesoc.css';
import './styles/fontawesome-pro-5.14.0-web/css/all.min.css';
import './styles/variables.css';
import Main from "./Main";
import FreshdeskWidgetProvider from "./Provider/FreshdeskWidgetProvider";
import useTexts from "./hooks/useTexte";
import ErrorModal from "./composant/Modal/ErrorModal";


function App() {



    return (

        <AuthProvider>
            <SearchProvider>

                <SystemProvider>

                    <Router>
                        <FreshdeskWidgetProvider>
                         <Main/>

                        </FreshdeskWidgetProvider>
                    </Router>
                </SystemProvider>
            </SearchProvider>
        </AuthProvider>


    );

}

export default App;
