import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { branchOptions } from '../Utils/BranchOption';
import { LevelOptions } from '../Utils/LevelOption';
import withAdminRole from "../hoc/withAdminRole";

// Fonction pour transformer les données JSON

function  Page404() {


    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>


                404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404
        </Box>
    );
}
export default Page404;

