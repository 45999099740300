import React, { createContext, useContext, useState } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";

// Création du contexte
const ConfirmContext = createContext();

// Fournisseur du contexte
export const ConfirmProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => () => {});

    const requestConfirm = (message, callback) => {
        setMessage(message);
        setOnConfirm(() => () => {
            callback();
            setIsVisible(false); // Fermer le dialogue après confirmation
        });
        setIsVisible(true);
    };

    return (
        <ConfirmContext.Provider value={{ requestConfirm }}>
            {children}
            {isVisible && (
                <>
                    <ConfirmDialog message={message} onConfirm={onConfirm} onCancel={() => setIsVisible(false)}
                                   isOpen={isVisible}
                    />
                </>

            )}
        </ConfirmContext.Provider>
    );
};

// Utilisation du contexte
export const useConfirm = () => {
    return useContext(ConfirmContext);
};

// Composant de dialogue de confirmation
const ConfirmDialog = ({ message, onConfirm, onCancel, isOpen }) => (
    <Dialog
        open={isOpen}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: { zIndex: 1400 } // Augmente le zIndex pour ce dialogue spécifique
        }}
    >
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onConfirm} color="primary" autoFocus>
                Confirmer
            </Button>
            <Button onClick={onCancel} color="secondary">
                Annuler
            </Button>
        </DialogActions>
    </Dialog>
);