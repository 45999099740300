import React from 'react';
import { Modal, Typography, Box, Button } from '@mui/material';
import cetncontent from './../../assets/cetncontent.jpg';
import useTexts from "../../hooks/useTexte"; // Tell webpack this JS file uses this image


const ValidateModal = ({ open, message, onClose}) => {
    const {t} = useTexts()

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "60%",
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                textAlign: 'center'
            }}>
               <img src={cetncontent} width={200}/>
                <Typography id="modal-description" sx={{ mt: 2 }}>
                    {message}
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Button variant="contained" onClick={onClose}>{t("BUTTON_FERMER")}</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ValidateModal;
