import React from 'react'
import PICTO_ECLAIREUR from '../assets/svg/pictos_branches/ECLAIREURS.svg';
import PICTO_ECLAIREUSE from '../assets/svg/pictos_branches/ECLAIREUSES.svg';
import PICTO_EQUIPIER_PILOTE from '../assets/svg/pictos_branches/EQUIPIERS_PILOTES.svg';
import EQUIPIERES_PILOTES from '../assets/svg/pictos_branches/EQUIPIERES_PILOTES.svg';
import PICTO_LOUVETEAUX from '../assets/svg/pictos_branches/LOUVETEAUX.svg';
import TERRITORIAL from '../assets/svg/pictos_branches/TERRITORIAL.svg';

export default function BranchesName({ line, styles }) {
    return (
        <div className='branches-name'>
            {line.lineTitle === 'Louvetisme' && <img src={PICTO_LOUVETEAUX} alt="Louvetisme" />}
            {line.lineTitle === 'Eclaireurs' && <img src={PICTO_ECLAIREUR} alt="Eclaireure" />}
            {line.lineTitle === 'Eclaireuses' && <img src={PICTO_ECLAIREUSE} alt="Eclaireuse" />}
            {line.lineTitle === 'Route' && <img src={PICTO_EQUIPIER_PILOTE} alt="Equipier Pilote" />}
            {line.lineTitle === 'Feu' && <img src={EQUIPIERES_PILOTES} alt="Equipière Pilote" />}
            {line.lineTitle === 'Territoriale' && <img src={TERRITORIAL} alt="Territorial" />}
            <h3 style={styles.title2}>{line.lineTitle}</h3>
        </div>
    )
}
