export const functionMapping = {
    "ASCOM": "Assistant du commissaire",
    "CH": "Chef",
    "COMAD": "Commissaire adjoint",
    "AMC": "Assistant maître de camp",
    "EQ": "Équipier pilote",
    "COM": "Commissaire",
    "MC": "Mestre de camp",
    "CADJ": "Chef adjoint",
    "D": "Délégué départemental",
    "ASSCH": "Assistant",
    "ADM": "Administrateur",
    "TRE": "Trésorier",
    "ADIR": "Assistant de direction",
    "ECO": "Économe",
    "SECR": "Secrétaire",
    "RESP": "Responsable",
    "EPG": "Équipière pilote",
    "EPS": "Équipier pilote",
    "ADMIN": "Administrateur",
    "DIR": "Directeur",
    "MAR": "Marraine",
    "COO": "Coordinateur",
    "PARN": "Parrain",
    "PDT": "Président",
    "ASCOML": "Assistant du commissaire louvetisme",
    "VPDT": "Vice-président"
};