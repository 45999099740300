import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useTexts from "../../hooks/useTexte";

const FeedbackFormModal = ({ open, onClose, children }) => {
    const {t} = useTexts()
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle id="form-dialog-title">

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 24 }}>
                {children}
            </DialogContent>
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 16 }}>
                <Button onClick={onClose} color="primary">
                    {t("BUTTON_FERMER")}
                </Button>
            </div>
        </Dialog>
    );
};

export default FeedbackFormModal;
