import {Button, styled} from "@mui/material";
import {green} from "@mui/material/colors";

export  const ValidateButton = styled(Button)(({theme}) => ({
    backgroundColor: green[500], // Custom green color
    color: 'white',
    '&:hover': {
        backgroundColor: green[700], // Darker on hover
    },
    padding: theme.spacing(1, 2), // Uniform padding around the text
    textTransform: 'none', // Prevents uppercase transformation for consistency
}));