import React from 'react';
import {Card, CardContent, CardMedia, Typography, Container, Grid} from '@mui/material';
import {useSpring, animated} from 'react-spring';

const AnimatedCard = animated(Card);

const MarketingBlock = ({image, title, description}) => {
    const props = useSpring({opacity: 1, from: {opacity: 0}, config: {duration: 1000}});

    return (
        <AnimatedCard style={props}>
            <CardMedia component="img" alt={title} height="140" image={image}/>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
        </AnimatedCard>
    );
};


export default MarketingBlock;