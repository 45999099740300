import React, { useState } from 'react';

import {
    Typography,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableRow,
    TableCell,

} from '@mui/material';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import { formatStatus } from "../../Utils/Status";
import { useConfirm } from "../../Provider/ConfirmProvider";
import useTexts from "../../hooks/useTexte";
import {useSystem} from "../../Context/System";
import dayjs from "dayjs";
import UserListPage from "../../Page/UserListPage";

const StagiareCardAdmin = ({ user, refreshUser , formation, index, isAdministrator, waitList}) => {
    const [formations, setFormations] = useState([]);
    const [open, setOpen] = useState(false);
    const fetchWithToken = useFetchWithToken();
    const {getConfiguration} = useSystem();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedCodeSycomore, setSelectedCodeSycomore] = useState('');

    const { requestConfirm } = useConfirm();
    const { t } = useTexts();
    const allowAddStatus = ["", "WAIT", "WAIT_VALIDATION", "REFUSE", "PROPOSITION"];
    const allowAlgoStatus = ["", "WAIT", "REFUSE", "PROPOSITION"];

    const handleOpenUserModal = (codeSycomore) => {
        setSelectedCodeSycomore(codeSycomore);
        setDialogOpen(true);
    };

    const validateStagiaire = async () => {
        requestConfirm(t("CONFIRMATION_ADD_STAGIAIRE"), async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${user.formation_id}/${user.user_id}/force`;
                await fetchWithToken(apiUrl);
                refreshUser();
            } catch (error) {
                console.error('Erreur lors de la récupération des tâches:', error);
                return [];
            }
        });
    };
    const allowStagiaireMoulinette = async () => {
        requestConfirm(t("CONFIRMATION_ADD_STAGIAIRE_MOULINETTE"), async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${user.formation_id}/${user.user_id}/allowmoulinette`;
                await fetchWithToken(apiUrl);
                refreshUser();
            } catch (error) {
                console.error('Erreur lors de la récupération des tâches:', error);
                return [];
            }
        });
    };

    const excludeStagiaireMoulinette = async () => {
        requestConfirm(t("CONFIRMATION_REMOVE_STAGIAIRE_MOULINETTE"), async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${user.formation_id}/${user.user_id}/excludemoulinette`;
                await fetchWithToken(apiUrl);
                refreshUser();
            } catch (error) {
                console.error('Erreur lors de la récupération des tâches:', error);
                return [];
            }
        });
    };



    const fetchFormations = async () => {
        try {
            const body = {
                branche: formation.branch,
                level: formation.level,
                page: 1
            };
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin`;
            const data = await fetchWithToken(apiUrl, 'POST', body);
            setFormations(data.formations); // Assuming the response is the list of formations
        } catch (error) {
            console.error('Erreur lors de la récupération des formations:', error);
        }
    };

    const moveToFormation = async (formationId) => {
        requestConfirm(t("CONFIRMATION_MOVE_STAGIAIRE"), async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/user/${user.formation_id}/${user.user_id}/move/${formationId}`;
                await fetchWithToken(apiUrl, 'POST');
                refreshUser();
                setOpen(false);
            } catch (error) {
                console.error('Erreur lors du déplacement du stagiaire:', error);
            }
        });
    };
    const deleteFromFormation = async (formationId) => {
        requestConfirm(t("CONFIRMATION_DELETE_STAGIAIRE"), async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${user.formation_id}/${user.user_id}/delete`;
                await fetchWithToken(apiUrl, 'POST');
                refreshUser();
            } catch (error) {
                console.error('Erreur lors du déplacement du stagiaire:', error);
            }
        });
    };
    const handleCloseUserModal = () => {
        setDialogOpen(false);
        setSelectedCodeSycomore('');
    };
    const getStatusStyle = (status) => {
        switch (status) {
            case 'WAIT':
                return {
                    backgroundColor: 'warning.main',
                    color: '#fff',
                };
            case 'WAIT_VALIDATION':
                return {
                    backgroundColor: 'info.main',
                    color: '#fff',
                };
            case 'PROPOSITION':
                return {
                    backgroundColor: 'info.main',
                    color: '#fff',
                };
            case 'VALIDATE':
                return {
                    backgroundColor: 'success.main',
                    color: '#fff',
                };
            case 'REFUSE':
                return {
                    backgroundColor: 'white',
                    color: 'red',
                };
            default:
                return {};
        }
    };
    const NB_RELANCE_VALIDATION_PLACE = parseInt(getConfiguration("NB_RELANCE_VALIDATION_PLACE"))
    const NB_RELANE_DAY = parseInt(getConfiguration("NB_RELANE_DAY"))

    const dateStatus = dayjs(user.date_status); // Conversion de la date
    const totalRelanceDays = (NB_RELANCE_VALIDATION_PLACE + 1) * NB_RELANE_DAY;
    console.log(NB_RELANCE_VALIDATION_PLACE)
    console.log(NB_RELANE_DAY)

    console.log(totalRelanceDays)

    // Ajout du nombre total de jours à la date d'origine
    const dateFinValidation = dateStatus.add(totalRelanceDays, 'day');


    return (
        <TableRow key={index}>

                <TableCell>{user.user_code_sycomore}</TableCell>
            <TableCell>
                <div>{user.user_firstname} {user.user_lastname}</div>
                <div><a onClick={() => handleOpenUserModal(user.user_code_sycomore)}>
                    Voir la fiche
                </a></div>
            </TableCell>
            <TableCell>{user.structure_nom}</TableCell>
            <TableCell>{user.fonction}</TableCell>
            <TableCell>
                { user.status == "WAIT_VALIDATION"  && (
                        <div>
                            {dateFinValidation.format('DD MMM YYYY')}
                        </div>
                )}

            </TableCell>
            <TableCell>
                    <Typography
                        sx={{
                            padding: 0.5,
                            borderRadius: 1,
                            ...getStatusStyle(user.status),
                        }}
                    >
                        {formatStatus(user.status, user.other_inscription_wait_validation, 1) }
                    </Typography>
                </TableCell>


                <TableCell>
                    {(isAdministrator && allowAlgoStatus.includes(user.status) && user.exclude_moulinette == 0)  && (
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ borderRadius: 2 }}
                            onClick={() => excludeStagiaireMoulinette(user)}
                        >
                            exclure de la moulinette
                        </Button>
                    )}

                    {(isAdministrator && allowAlgoStatus.includes(user.status)  && user.exclude_moulinette == 1)  && (
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ borderRadius: 2 }}
                            onClick={() => allowStagiaireMoulinette(user)}
                        >
                            remettre  dans la moulinette
                        </Button>
                    )}

                    {isAdministrator && allowAddStatus.includes(user.status) && (
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ borderRadius: 2 }}
                            onClick={() => validateStagiaire(user)}
                        >
                            Valider
                        </Button>
                    )}

                    {isAdministrator && user.status === "VALIDATE" && (
                        <>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{ marginLeft: 2, borderRadius: 2 }}
                                onClick={() => {
                                    fetchFormations();
                                    setOpen(true);
                                }}
                            >
                                Déplacer
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{ marginLeft: 2, borderRadius: 2 , color:"red"}}
                                onClick={() => {
                                    deleteFromFormation()
                                }}
                            >
                               Supprimer
                            </Button>
                        </>

                    )}
                </TableCell>
            <Dialog
                open={dialogOpen}
                onClose={handleCloseUserModal}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>Informations Utilisateur</DialogTitle>
                <DialogContent>
                    <UserListPage codeSycomoreData={selectedCodeSycomore}/>
                </DialogContent>
                <Button onClick={handleCloseUserModal}>Fermer</Button>
            </Dialog>


            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>Déplacer le stagiaire</DialogTitle>
                <DialogContent>
                    {formations.length > 0 && (
                        <FormControl fullWidth sx={{ marginTop: 2 }}>
                            <InputLabel>Formation</InputLabel>
                            <Select
                                value=""
                                onChange={(e) => moveToFormation(e.target.value)}
                            >
                                {formations.map((formation) => (
                                    <MenuItem key={formation.id} value={formation.id}>
                                        {formation.nom}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        </TableRow>

    );
};

export default StagiareCardAdmin;
