import React from 'react';
import {Card, CardContent, Typography, IconButton, Box} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

const DocumentCard = ({document, onDelete, onDownload}) => {
    return (
        <Card sx={{
            maxWidth: 345,
            textAlign: 'center',
            border: '2px solid #ccc',
            borderRadius: '10px',
            overflow: 'visible'
        }}>
            <CardContent>
                <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "20px"}} gutterBottom variant="h5"
                            component="div">{document.nom}</Typography>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0'}}>
                    <IconButton onClick={onDownload}><DownloadIcon/></IconButton>
                    {onDelete && (

                        <IconButton onClick={onDelete}><DeleteIcon color="error"/></IconButton>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default DocumentCard;
