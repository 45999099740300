import React from 'react';
import HeaderLogo from "./HeaderLogo";
import HeaderProfile from "./HeaderProfile";
import Navbar from "./Navbar/Navbar";
import './styles/Header.css';

const Header = () => {
    return (
        <>
            <div className="upper-header">
                <HeaderLogo/>
                <HeaderProfile/>
            </div>
            <Navbar/>
        </>
    );
};

export default Header;
