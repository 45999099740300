import React from 'react';
import { Autocomplete, TextField, FormControl } from '@mui/material';
import useTexts from "../hooks/useTexte";

function StructureAutocomplete({ data, selectedData, handleChange, label }) {
    const { t } = useTexts();

    // Transformation des options pour correspondre au format attendu par Autocomplete
    const options = data.map((option) => ({
        id: option.id,
        label: `${option.id_sycomore} ${option.nom}`,
    }));

    // Trouver l'option sélectionnée actuelle pour l'affichage
    const selectedOption = options.find((option) => option.id === selectedData) || null;

    return (
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120, flex: '1 1 auto' }}>
            <Autocomplete
                options={options}
                getOptionLabel={(option) => option.label}
                value={selectedOption}
                onChange={(event, newValue) => handleChange(newValue ? newValue.id : '')}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        placeholder={t("SEARCH")}
                    />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={t("NO_OPTIONS")}
                clearOnEscape
            />
        </FormControl>
    );
}

export default StructureAutocomplete;
