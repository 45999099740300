import React from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box
} from '@mui/material';
import {branchOptions} from "../../Utils/BranchOption";
import {LevelOptions} from "../../Utils/LevelOption";
import {formatDate} from "../../Utils/functionTransforms";

const UserFormations = ({f}) => {
    const branch = branchOptions.find((option) => f.section_id === option.id);
    const level = LevelOptions.find((option) => f.level === option.level && option.section.includes(branch.key));

    return (
        <Card sx={{boxShadow: 3, borderRadius: 3, overflow: 'hidden'}}>
            <Box display="flex" justifyContent="center" p={2}>
                <CardMedia
                    component="img"
                    sx={{width: 64, height: 64, objectFit: 'contain'}}
                    image={branch.logo}
                    alt={branch.name}
                />
            </Box>
            <CardContent sx={{textAlign: 'center'}}>
                <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                    {level.label}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Date : {formatDate(f.date_formation)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Avis : {f.Avis}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default UserFormations;
