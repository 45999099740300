import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
    const [searchTerms, setSearchTerms] = useState({
        selectedType: '',
        selectedBranche: '',
    });

    const updateSearchTerms = (terms) => {
        setSearchTerms(terms);
    };

    return (
        <SearchContext.Provider value={{ searchTerms, updateSearchTerms }}>
            {children}
        </SearchContext.Provider>
    );
};
