import React from 'react';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { TableVirtuoso } from 'react-virtuoso';

export default function TableVirtualize({ texts, handleDialogOpen }) {
    const columns = [
        { dataKey: 'key', label: 'KEY_LABEL' },
        { dataKey: 'text', label: 'Texte' },
        { dataKey: 'actions', label: 'Actions' }
    ];

    function fixedHeaderContent() {
        return (
            <TableRow>
            {columns.map((column, index) => (
                <TableCell
                key={index}
                variant="head"
                style={{
                    backgroundColor: 'ghostwhite',
                    fontWeight: 'bold',
                    width: column.dataKey === 'actions' ? '100px' : '300px',
                }}
                >
                {column.label}
                </TableCell>
            ))}
            </TableRow>
        );
    }

    function rowContent(index, row) {
        return (
            <>
                {columns.map((column, colIndex) => (
                    <TableCell
                        key={colIndex}
                        style={{
                            width: column.dataKey === 'actions' ? 'fit-content' : '300px',
                            textAlign: column.dataKey === 'actions' ? 'right' : 'left',
                        }}
                    >
                        {column.dataKey === 'actions' ? (
                            <div className="actions-button">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleDialogOpen(row, 'edit')}
                                >
                                    Modifier
                                </Button>
                                {/* <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleDialogOpen(row, 'delete')}
                                >
                                    Supprimer
                                </Button> */}
                            </div>
                        ) : (
                            row[column.dataKey]
                        )}
                    </TableCell>
                ))}
            </>
        );
    }

    return (
        <Paper style={{ height: 500, width: '100%' }}>
            <TableVirtuoso
                data={texts}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
                style={{ height: '100%' }}
            />
        </Paper>
    );
}
