import React from 'react';
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import CompetenceTable from "./CompetenceTable";

const FormFieldView = ({field, sectionIndex, formState}) => {

    let fieldValue = "";
    let readOnly = true;
    if (formState) {
        fieldValue = formState[`${sectionIndex}_${field.name}`] || '';

    } else {
        readOnly = false
    }

    switch (field.type) {
        case 'text':
        case 'textarea':
            return (
                <TextField
                    label={field.label}
                    multiline={field.type === 'textarea'}
                    rows={field.type === 'textarea' ? 4 : 1}
                    variant="outlined"
                    fullWidth
                    value={fieldValue}
                    inputProps={{readOnly: readOnly}}
                />
            );

        case 'select':

            return (
                <FormControl fullWidth>
                    <InputLabel>{field.label}</InputLabel>
                    <Select
                        value={fieldValue}
                        displayEmpty
                        inputProps={{readOnly: readOnly}}
                    >
                        {field.options.map(option => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );

        case 'checkbox':
         
            return (


                field.options.map(option => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={fieldValue.includes(option)}
                            />
                        }
                        label={option}
                    />
                ))


            );

        case 'radio':
            return (
                <FormControl>
                    <RadioGroup
                        row
                        value={fieldValue}
                    >
                        {field.options.map(option => (
                            <FormControlLabel key={option} value={option} control={<Radio readOnly/>} label={option}/>
                        ))}
                    </RadioGroup>
                </FormControl>
            );
        case 'competences':
            return <CompetenceTable
                field={field}
                sectionIndex={sectionIndex}
                formState={formState}
                readonly={1}
            />
            break;
        default:
            return null;
    }
};


export default FormFieldView;
