import React, {useEffect, useState} from 'react';
import {
    Button,
    Typography,
    Alert,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Card,
    CardContent, Modal, Box, Select, MenuItem,
} from '@mui/material';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningIcon from '@mui/icons-material/Warning';

import {branchOptions} from '../../Utils/BranchOption';
import {LevelOptions} from '../../Utils/LevelOption';

import {calculateEndDate, formatDate, transformFunctionOption} from '../../Utils/functionTransforms';
import {formatErrors, formatErrorsArray} from "../../Utils/formatErrors";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import useTexts from "../../hooks/useTexte";
import AnimateButton from "../Core/AnimateButton";
import {ValidateButton} from "../../Utils/ValidateButton";
import {AnimatedIcon} from "../../Utils/AnimatedIcon";
import {useAuth} from "../../Context/AuthContext";
import { CheckCircleOutlineRounded } from '@mui/icons-material';

const WishListTable = ({wish, onDelete, index, type, realodWish}) => {
    const fetchWithToken = useFetchWithToken();
    const [formationData, setFormationData] = useState([]);
    const [loadFormationData, setLoadFormationData] = useState(true)
    const [isRequireValidate, setIsRequireValidate] = useState(false)
    const [openValidation, setOpenValidation] = useState(false)
    const [wishValidation, setWishValidation] = useState(0)
    const [formation, setFormation] = useState(0)
    const {functionOptions,} = useAuth();
    const [openChangeFunction, setOpenChangeFunction] = useState(false);

    const [saveFunction, setSaveFunction] = useState(false);
    const handleChangeFunctionOpen = () => {
        setSaveFunction(wish.function_id)
        setOpenChangeFunction(true);

    }
    const handleChangeFunctionClose = () => setOpenChangeFunction(false);
    const {t} = useTexts()
    const renderFormationStatus = () => {
        if (wish.valide) {
            if (!formationData.length) {
                return <Typography sx={{fontFamily: "ubuntu"}} variant="body1"
                                   className={"noformation"}>{t("TEXT_NO_FORMATION")}</Typography>;
            }
            if (!isRequireValidate) {
                return formationData.map((formation, idx) => {
                    let text = "";
                    if (formation.status == "open"){
                        text = t("WAIT_ATTRIBUTION")
                    } else  if (formation.status == "selecting"){
                        text =t("WAIT_LIST_FORMATION_RUN")
                    } else if (formation.status == "selected"){
                        text  =t("WAIT_LIST_FORMATION")
                    }
//
                    return (
                        <div key={idx} className={""}>
                            <Typography sx={{fontFamily: "ubuntu"}} variant="body1"
                                        className={""}>{formation.nom}</Typography>
                            <Typography sx={{fontFamily: "ubuntu"}} variant="body1"
                                        className={"inwaitlist waitrun"}>{text}</Typography>
                        </div>
                    );
                });
            } else {
                let userHasValide = false
                formationData.map((formation, idx) => {
                    if (formation.statusValidation) {
                        if (formation.statusValidation.find(f => f.role == 1)) {
                            userHasValide = true;
                        }

                    }

                })


                if (userHasValide == false) {
                    return (
                        <Typography sx={{
                            display: {xs: 'block', sm: 'flex'},  // 'block' sur mobile, 'flex' sur les écrans plus grands

                            fontFamily: "ubuntu", alignItems: 'center', gap: 1
                        }}>
                            <span
                                style={{
                                    color: 'green',
                                    marginRight: '4px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    gap: '2px'
                                }}
                            >
                                <CheckCircleOutlineRounded color="success" /> Place attribuée
                            </span>
                            <ValidateButton
                                onClick={() => {
                                    setWishValidation(wish)
                                    setOpenValidation(true)
                                }}
                                startIcon={<AnimatedIcon/>}
                                className="unique-validate-button"
                            >
                                Valider l'inscription
                                <style jsx global>{`
                                    .unique-validate-button:hover .MuiSvgIcon-root {
                                        transform: translateX(15px); // Déplace l'icône vers la gauche au survol
                                    }
                                `}</style>
                            </ValidateButton>

                        </Typography>
                    );
                } else {
                    return <Typography sx={{fontFamily: "ubuntu"}} variant="body1"
                                       className={"noformation"}>{t("WAIT_CHEF_VALIDATION")}</Typography>;

                }
            }
        } else {
            return (
                <>
                    <Typography
                        sx={{ fontFamily: "ubuntu", fontWeight: 'bold' }}
                        variant="body1"
                        className="requirevalidation"
                        dangerouslySetInnerHTML={{ __html: t("TEXT_INSCRIPTON_NEED_DEROGATION", ) }}
                    />
                    {wish.invalide_data && wish.invalide_data.length > 0 && (
                        <ul>
                            {formatErrorsArray(wish.invalide_data, t).map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    )}
                </>

            );
        }
    };
    const onValidate = async () => {
        try {
            const body = {
                formation_user_id: formation.statusInscription.id,
            }
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formation/validate`;
            await fetchWithToken(apiUrl, "POST", body);
            setOpenValidation(false)
            loadWishesData();
            realodWish();
        } catch (error) {
            console.log(error)
        } finally {
        }
    };
    const loadWishesData = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes/${wish.id}/data`;
            const data = await fetchWithToken(apiUrl);
            setFormationData(data.formations)
            data.formations.map((formation, idx) => {
                if (formation.statusInscription) {
                    if (formation.statusInscription.status === 'WAIT_VALIDATION') {
                        setIsRequireValidate(true);
                        setFormation(formation)

                    }
                }
            });
        } catch (error) {
            console.log(error)
        } finally {
        }
        setLoadFormationData(false)
    };
    useEffect(() => {
        setOpenChangeFunction(false);
        loadWishesData();
    }, []);

    const handleUpdateFunction = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/wishes/${wish.id}/updateFunction/${saveFunction}`;
            await fetchWithToken(apiUrl, "POST");
            setOpenChangeFunction(false);
            realodWish()
        } catch (error) {
            console.error('Erreur lors :', error);
        }
    };

    const level = LevelOptions.find((branch) => wish.level === branch.key)
    const chefFunction = functionOptions.find(func => func.id == wish.function_id);

    const branch = branchOptions.find((branch) => wish.branch === branch.key)
    return (
        <div style={{marginTop: '32px'}}>
            <Dialog open={openValidation} onClose={() => setOpenValidation(false)}>
                <DialogTitle>{t("TEXT_VALIDER_PLACE_FORMATION")}</DialogTitle>
                <DialogContent>
                    <Typography>{t("TEXT_YOU_HAVE_TAKE_TO_FORMATION")}</Typography>
                    <ul>
                        <li>{t("TEXT_COL_BRANCHE")} : {wishValidation.branch}</li>
                        <li>{t("TEXT_COL_TYPE")} : {wishValidation.level}</li>
                        <li>{t("TEXT_COL_DATE")} : {formatDate(wishValidation.date)} au {calculateEndDate(wishValidation.date)}</li>
                    </ul>
                    <Alert severity="warning">
                        {t("TEXT_WARNING_VALIDATION")}
                    </Alert>

                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setOpenValidation(false)}>
                        {t("BUTTON_ANNULER")}
                    </Button>
                    <Button color="primary" onClick={() => onValidate()}>
                        {t("BUTTON_VALIDER")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Card sx={{
                width: '100%',
                marginBottom: 2,
                boxShadow: 'none', // retire l'ombre
                border: '1px solid #ccc', // ajoute une bordure
                borderRadius: 2 // ajoute un rayon de bordure

            }}>

                <CardContent
                    sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, alignItems: 'center', gap: 2}}>

                    <div style={{flex: '0 0 30px', marginBottom: {xs: 2, sm: 0}}}>
                        <img src={branch.logo} alt="Training" style={{width: '50px', height: '50px'}}/>
                    </div>

                    <div style={{flex: '1 1 20%', marginBottom: {xs: 2, sm: 0}}}
                         className={type !== "delete" ? "headlineLevel" : ""}>
                        {level.label}
                    </div>

                    <div style={{flex: '1 1 20%', marginBottom: {xs: 2, sm: 0}}}>

                        {
                            chefFunction ? (
                                transformFunctionOption(chefFunction)

                            ) : (
                                <div>{t("INVALIDE_FUNCTION_NEED_ACTION")}</div>
                            )
                        }

                        <Button variant="outlined" onClick={handleChangeFunctionOpen} sx={{marginTop: 1}}>
                            {t("FUNCTION_WISH_CHANGE")}
                        </Button>
                    </div>

                    <div style={{flex: '1 1 20%', marginBottom: {xs: 2, sm: 0}}}>

                        <div>du {formatDate(wish.date)}</div>
                        <div>au {calculateEndDate(wish.date)}</div>
                    </div>

                    {
                        type !== "delete" && (
                            <div style={{flex: '1 1 40%', alignContent: "center", marginBottom: {xs: 2, sm: 0}}}>
                                {renderFormationStatus()}
                            </div>
                        )
                    }
                    {
                        type !== "delete" && (
                            <div style={{
                                flex: {xs: 'unset', sm: '0 0 5%'},  // 'unset' retire flex sur mobile, '0 0 5%' l'applique sur les écrans plus grands
                                marginBottom: {xs: 2, sm: 0},       // marginBottom de 2 sur mobile, 0 sur les écrans plus grands
                                marginLeft: {xs: 'unset', sm: 'auto'} // retire marginLeft sur mobile, l'applique sur les écrans plus grands
                            }}
                                 onClick={() => onDelete(wish.voeu_id)}>
                                <AnimateButton type={"delete"}/>
                            </div>
                        )
                    }
                </CardContent>
            </Card>
            <Modal
                open={openChangeFunction}
                onClose={handleChangeFunctionClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>
                    <h2 id="modal-modal-title">{t("FUNCTION_WISH_CHANGE")}</h2>
                    <p id="modal-modal-description">{t("FUNCTION_WISH_CHANGE_TEXT")}</p>
                    {functionOptions && functionOptions.length >= 1 && (
                        <Select
                            value={saveFunction}
                            displayEmpty
                            onChange={(event) => setSaveFunction(event.target.value)}
                            fullWidth
                        >
                            {functionOptions.map(({fonction, id, nom, type}) => (
                                <MenuItem key={id} value={id}>
                                    {nom} - {fonction}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    <Button onClick={handleChangeFunctionClose} variant="contained">
                        {t("BUTTON_FERMER")}
                    </Button>
                    <Button onClick={handleUpdateFunction} variant="contained">
                        {t("VALIDATE_FUNCTION_BTN")}
                    </Button>
                </Box>
            </Modal>
        </div>
    )
        ;
};

export default WishListTable;
