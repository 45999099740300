import React, { Suspense, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import client, { urlFor } from '../sanityClient';
import { PortableText } from '@portabletext/react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import './styles/CMSTop.css';

const ContentBlockRenderer = React.lazy(() => import('../composant/ContentBlockRenderer'));

function CMSTop({ cmsData }) {
    return (
        <div className="cmspageWrap">
            {cmsData.imageHero ? (
                <div className="hero">
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                            <div>
                                <Typography sx={{ fontFamily: "ubuntu" }} variant="h1" component="h1" gutterBottom className="cmsHerotitle">
                                    {cmsData.title}
                                </Typography>
                            </div>
                            <div>
                                <Typography sx={{ fontFamily: "ubuntu" }} variant="body1" component="p" className="cmsHeroDesctitle">
                                    <PortableText value={cmsData.description} />
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <img
                            className='img-hero-accueil'
                            src={urlFor(cmsData.imageHero).url()}
                            alt=""
                        />
                    </Grid>
                </div>
            ) : (
                <div className="hero">
                    <Grid container spacing={0}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                                p: 1,
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{ fontFamily: 'ubuntu', color: '#B71234', fontSize: "3rem" }}
                                variant="h1"
                                component="h1"
                                gutterBottom
                                className="cmsHerotitle"
                            >
                                {cmsData.title}
                            </Typography>
                        </Box>
                    </Grid>
                </div>
            )}
            {cmsData.content?.map((block, index) => (
                <ContentSection key={index} block={block} />
            ))}
        </div>
    );
}

const ContentSection = ({ block }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const [loadContent, setLoadContent] = useState(false);

    useEffect(() => {
        if (inView) {
            setLoadContent(true);
        }
    }, [inView]);

    return (
        <div ref={ref}>
            {loadContent ? (
                <Suspense fallback={<div>Loading...</div>}>
                    <ContentBlockRenderer block={block} />
                </Suspense>
            ) : (
                <CircularProgress />
            )}
        </div>
    );
};

export default CMSTop;
