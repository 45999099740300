import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, CircularProgress, Grid } from "@mui/material";
import useTexts from "../hooks/useTexte";
import { useSystem } from "../Context/System";
import AgendaItem from "../composant/AgendaItem";

const AgendaPage = () => {
    const [formations, setFormations] = useState([]);
    const [loading, setLoading] = useState(true);

    const { currentYear } = useSystem();
    const { t } = useTexts();

    useEffect(() => {
        // Récupérer les formations depuis l'API
        fetch(`${process.env.REACT_APP_API_URL}/api/pubformation`)
            .then(response => response.json())
            .then(data => {
                setFormations(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Erreur lors du chargement des formations :", error);
                setLoading(false);
            });
    }, []);

    // Fonction pour regrouper les formations par période
    const groupByPeriod = (data) => {
        return data.reduce((groups, formation) => {
            const period = formation.nom; // 'nom' est la période ici (été, noel, etc.)
            if (!groups[period]) {
                groups[period] = [];
            }
            groups[period].push(formation);
            return groups;
        }, {});
    };


    if (!currentYear){
        return "<></>";
    }
    // Regrouper les formations par période
    const groupedFormations = groupByPeriod(formations);
    const year= new Date(currentYear.start)

    return (
        <Container maxWidth="md" sx={{ padding: '20px' }}>
            {loading ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : (
                Object.keys(groupedFormations).map(period => (
                    <Box key={period} mb={4}>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', fontSize: '24px', color: '#3f51b5' }}>
                            {period} {year.getFullYear() + groupedFormations[period][0].year_cal}
                        </Typography>
                        <Grid container spacing={3}>
                            {groupedFormations[period].map((formation, index) => (
                                <Grid item xs={12} sm={12} key={index}>
                                    <AgendaItem formation={formation} index={index} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ))
            )}
        </Container>
    );
};

export default AgendaPage;
