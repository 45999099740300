import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './styles/PreviewModal.css';
import { MenuItem } from '@mui/material';

const PreviewModal = ({ open, onClose, sections = [] }) => {
    const [selectedLevels, setSelectedLevels] = useState({});

    const handleRadioChange = (competenceIndex, level) => {
        setSelectedLevels((prevLevels) => ({
            ...prevLevels,
            [competenceIndex]: level,
        }));
    };

    if (sections.length < 1) {
        return null;
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box className="preview-modal" sx={{ padding: 4, backgroundColor: 'white', maxWidth: '80%', margin: 'auto', marginTop: '5%' }}>
                <Typography variant="h5" gutterBottom>
                    Aperçu du formulaire
                </Typography>
                <form className="preview-form">
                    {sections.map((section, sectionIndex) => (
                        <Box key={sectionIndex} className="section-preview" sx={{ marginBottom: 4 }}>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                                {section?.title || `Section ${sectionIndex + 1}`}
                            </Typography>
                            {section.fields?.map((question, questionIndex) => (
                                <Box key={questionIndex} className="question-preview" sx={{ marginBottom: 3 }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 500, marginBottom: 1 }}>
                                        {question.label || `Question ${questionIndex + 1}`}
                                    </Typography>

                                    {question.type === 'text' && (
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            placeholder="Réponse courte"
                                            sx={{ marginBottom: 2 }}
                                        />
                                    )}

                                    {question.type === 'textarea' && (
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            placeholder="Réponse longue"
                                            sx={{ marginBottom: 2 }}
                                        />
                                    )}

                                    {(question.type === 'radio') && (
                                        <RadioGroup>
                                            {question.options?.map((option, optionIndex) => (
                                                <FormControlLabel
                                                    key={optionIndex}
                                                    value={option}
                                                    control={<Radio />}
                                                    label={option || `Option ${optionIndex + 1}`}
                                                />
                                            ))}
                                        </RadioGroup>
                                    )}

                                    {question.type === 'checkbox' && (
                                        <Box>
                                            {question.options?.map((option, optionIndex) => (
                                                <FormControlLabel
                                                    key={optionIndex}
                                                    control={<Checkbox />}
                                                    label={option || `Option ${optionIndex + 1}`}
                                                />
                                            ))}
                                        </Box>
                                    )}

                                    {question.type === 'select' && (
                                        <TextField
                                            select
                                            label="Choisir une option"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ marginTop: 1 }}
                                        >
                                            {question.options?.map((option, optionIndex) => (
                                                <MenuItem key={optionIndex} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}

                                    {question.type === 'competences' && question.competences && (
                                        <TableContainer sx={{ marginTop: 2 }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        {question.options.map((level, levelIndex) => (
                                                            <TableCell key={levelIndex} align="center">
                                                                {level}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {question.competences.map((competence, competenceIndex) => (
                                                        <TableRow key={competenceIndex}>
                                                            <TableCell component="th" scope="row">
                                                                {competence || `Compétence ${competenceIndex + 1}`}
                                                            </TableCell>
                                                            {question.options.map((level, levelIndex) => (
                                                                <TableCell key={levelIndex} align="center">
                                                                    <Radio
                                                                        checked={selectedLevels[competenceIndex] === level}
                                                                        onChange={() => handleRadioChange(competenceIndex, level)}
                                                                        value={level}
                                                                        name={`competence-${competenceIndex}`}
                                                                    />
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </form>
                <Button onClick={onClose} variant="contained" color="primary" sx={{ mt: 3 }}>
                    Fermer
                </Button>
            </Box>
        </Modal>
    );
};

export default PreviewModal;
