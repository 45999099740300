import React from 'react';
import { PortableText } from '@portabletext/react';
import { urlFor } from "../sanityClient";
import { motion } from 'framer-motion';
import BranchesName from './BranchesName';
import './styles/ColBlock.css';

const ColBlock = ({ title, textColor, backgroundColor, lines }) => {
    const styles = {
        progressionCMS: {
            backgroundColor: backgroundColor.hex,
            color: textColor.hex,
            padding: '20px',
        },
        title: {
            textAlign: 'center',
            color: textColor.hex,
        },
        title2: {
            textAlign: 'left',
            color: 'white',
        },
        title3: {
            color: textColor.hex,
            fontSize: "30px",
        },
        gridContainer: (numItems) => ({
            display: 'grid',
            gridTemplateColumns: `repeat(${numItems}, 1fr)`,
            gap: '20px',
            width: '100%',
        }),
        itemContainer: {
            alignItems: 'center',
        },
        container: {
            width: "90%",
            margin: "auto",
        },
        itemNumber: {
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: textColor.hex,
            color: backgroundColor.hex,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto 10px auto',
        },
        separator: {
            border: `1px solid ${textColor.hex}`,
            width: '80%',
            margin: '20px auto',
        },
        textContent: {
            textAlign: "left",
            fontSize: "16px",
            fontWeight: "500",
        },
    };

    const containerVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5, staggerChildren: 0.3 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    };

    return (
        <div className="progressionCMS" style={styles.progressionCMS}>
            <h2 style={styles.title}>{title}</h2>
            {lines.map((line, indexLine) => (
                <div key={indexLine} className='branches-details'>
                    <BranchesName line={line} styles={styles} />
                    <div style={styles.container} className='section-formations'>
                        <motion.div
                            className="colitemwrap"
                            style={styles.gridContainer(line.items.length)}
                            initial="hidden"
                            animate="visible"
                            variants={containerVariants}
                        >
                            {line.items.map((item, index) => (
                                <motion.div
                                    key={index}
                                    className="colitem"
                                    style={styles.itemContainer}
                                    variants={itemVariants}
                                >
                                    {item.image && <img className="imgfix" src={urlFor(item.image.asset).width(520).height(340).fit('crop').url()} alt={item.title} />}
                                    <h4 style={styles.title3}>{item.title}</h4>
                                    <div className='criteria'>
                                        <PortableText value={item.blockText} />
                                    </div>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ColBlock;
