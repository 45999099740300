import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Question = ({
    question,
    sectionIndex,
    questionIndex,
    updateQuestion,
    updateOption,
    removeOption,
    addOption,
    removeQuestion,
    questionTypes,
    addCompetence,
    updateCompetence,
    removeCompetence,
    addLevel,
    updateLevel,
    removeLevel,
    moveUpOption,
    moveDownOption,
}) => {
    return (
        <Box sx={{ marginBottom: 2 }}>
            <TextField
                label="Question"
                fullWidth
                value={question.label || ''}
                onChange={(e) =>
                    updateQuestion(sectionIndex, questionIndex, {
                        ...question,
                        label: e.target.value,
                    })
                }
                sx={{ marginBottom: 2 }}
                variant='filled'
            />

            <TextField
                select
                label="Type de question"
                value={question.type}
                onChange={(e) =>
                    updateQuestion(sectionIndex, questionIndex, {
                        ...question,
                        type: e.target.value,
                    })
                }
                fullWidth
                sx={{ marginBottom: 2 }}
            >
                {questionTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <span id='questionTypeLabel'>{option.icon}</span>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            {['radio', 'checkbox', 'select'].includes(question.type) && (
                <>
                    {question.options?.map((option, optionIndex) => (
                        <TextField
                            key={optionIndex}
                            label={`Option ${optionIndex + 1}`}
                            fullWidth
                            variant='standard'
                            value={option || ''}
                            onChange={(e) =>
                                updateOption(sectionIndex, questionIndex, optionIndex, e.target.value)
                            }
                            InputProps={{
                                endAdornment: (
                                    <div className='input-endIcons'>
                                        <IconButton
                                            aria-label="monter l'option"
                                            onClick={() => moveUpOption(sectionIndex, questionIndex, optionIndex)}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="descendre l'option"
                                            onClick={() => moveDownOption(sectionIndex, questionIndex, optionIndex)}
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => removeOption(sectionIndex, questionIndex, optionIndex)}
                                            aria-label="supprimer l'option"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </div>
                                ),
                            }}
                            sx={{ marginBottom: 1 }}
                        />
                    ))}
                    <Button
                        startIcon={<AddCircleIcon />}
                        onClick={() => addOption(sectionIndex, questionIndex)}
                    >
                        Ajouter une option
                    </Button>
                </>
            )}

            {question.type === 'competences' && (
                <Box sx={{ marginBottom: 2 }}>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Ligne - Compétences
                    </Typography>

                    {question.competences?.map((competence, competenceIndex) => (
                        <Box key={competenceIndex} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                            <TextField
                                label={`Compétence ${competenceIndex + 1}`}
                                fullWidth
                                variant='filled'
                                value={competence || ''}
                                onChange={(e) =>
                                    updateCompetence(sectionIndex, questionIndex, competenceIndex, e.target.value)
                                }
                                sx={{ marginBottom: 1 }}
                            />
                            <IconButton
                                color="error"
                                onClick={() => removeCompetence(sectionIndex, questionIndex, competenceIndex)}
                                aria-label="supprimer la compétence"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}

                    <Button
                        startIcon={<AddCircleIcon />}
                        onClick={() => addCompetence(sectionIndex, questionIndex)}
                        sx={{ marginBottom: 2 }}
                    >
                        Ajouter une compétence
                    </Button>

                    <Typography variant="subtitle1" sx={{ marginBottom: 1, marginTop: 2 }}>
                        Colonne - Niveaux
                    </Typography>

                    {question.options?.map((level, levelIndex) => (
                        <Box key={levelIndex} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                            <TextField
                                label={`Niveau ${levelIndex + 1}`}
                                fullWidth
                                variant='standard'
                                value={level || ''}
                                onChange={(e) => updateLevel(sectionIndex, questionIndex, levelIndex, e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() => removeLevel(sectionIndex, questionIndex, levelIndex)}
                                            aria-label="supprimer le niveau"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Box>
                    ))}

                    <Button
                        startIcon={<AddCircleIcon />}
                        onClick={() => addLevel(sectionIndex, questionIndex)}
                        sx={{ marginBottom: 2 }}
                    >
                        Ajouter un niveau
                    </Button>
                </Box>
            )}

            <Button
                variant="outlined"
                color="error"
                onClick={() => removeQuestion(sectionIndex, questionIndex)}
                sx={{ mt: 2 }}
                startIcon={<DeleteIcon />}
            >
                Supprimer la question
            </Button>
        </Box>
    );
};

export default Question;
