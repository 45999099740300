import React, {useEffect, useState} from 'react';
import {

    Grid,
    Container, Box, CircularProgress, Tooltip, IconButton, Button, Dialog, DialogContent, DialogTitle,

} from '@mui/material';


import {useAuth} from '../Context/AuthContext';

import {useSystem} from "../Context/System";
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import useTexts from "../hooks/useTexte";
import {useParams} from "react-router-dom";
import StagiareCard from "../composant/Formation/StagiareCard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {useConfirm} from "../Provider/ConfirmProvider";


const FormationManagerPage = () => {
    const {id} = useParams("id")
    const { requestConfirm } = useConfirm(); // Utiliser le hook pour obtenir la fonction de demande de confirmation
    const [keyModal, setKeyModal] = useState(0); // Utilisé pour forcer la recréation de FormBuilder

    const [formation, setFormation] = useState(null)
    const [users, setUsers] = useState([]);
    const fetchWithToken = useFetchWithToken();
    const [attachements, setAttachements] = useState([]);
    const [isLoading , setIsloAding] = useState(true);
    const {t} = useTexts()
    const [openFormModal, setOpenFormModal] = useState(false);
    const [initialFormData, setInitialFormData] = useState(null);
    const [hasUnsavedChangesFiche, setHasUnsavedChangesFiche] = useState(false);

    const handleCloseFormModal = () => {

        if (hasUnsavedChangesFiche) {
            // Demander la confirmation si des modifications non sauvegardées sont présentes
            requestConfirm(t("MODIFICATION_LOST_WARNING"), () => {
                setOpenFormModal(false); // Fermer le modal si l'utilisateur confirme
            });
        } else {
            // Fermer le modal directement si aucune modification n'est détectée
            setOpenFormModal(false);
        }
        setHasUnsavedChangesFiche(false)


    };

    const handleOpenFormModal = (id) => {
        setKeyModal(keyModal+1)
       setInitialFormData(id);
        setOpenFormModal(true);
    };
    const loadFormation = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${id}`;
        const data = await fetchWithToken(apiUrl);
        setFormation(data)
        setIsloAding(false)

    };

    const listUserSelecing = async () => {
        try {
            const apiUrlAttachement = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${id}/attachements`;
            await fetchWithToken(apiUrlAttachement).then(data => {
                setAttachements(data)

            });

            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${id}/selected`;
            await fetchWithToken(apiUrl).then(data => {
                setUsers(data)

            });


        } catch (error) {
        } finally {
        }

    };

    useEffect(() => {
        if (id) {
            loadFormation();
            listUserSelecing();
        }
    }, []); // Le tableau vide assure que l'effet s'exécute une seule fois au montage
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }
    const hasQuestionaitre =  formation.questionaire_id ? true: false;

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                {hasQuestionaitre === true && (
                    <>
                        <Button onClick={() => handleOpenFormModal(formation.questionaire_id )}>Modifier le questionnaire stagiaire</Button>

                    </>
                )}
            </Grid>
            <Grid container spacing={2}>
                {users && attachements && users.map((user, index) => (
                    <StagiareCard key={index} user={user}
                                  attachments={attachements.filter((attachement => attachement.formation_user_id === user.formation_user_id))}/>
                ))}

            </Grid>
           
        </Container>
    );
};

export default FormationManagerPage;
