export  const LevelOptions = [
    { key: 'cep1', label: 'CEP 1', section: ["louvetisme", "eclaireur", "eclaireuse"] , "freedate":true, "level":1},
    { key: 'cep2', label: 'CEP 2', section: ["louvetisme", "eclaireur", "eclaireuse"] ,"freedate":true, "level":2},
    { key: 'campnationaux', label: 'Camp national', section: ["louvetisme", "eclaireur", "eclaireuse"] ,"freedate":false, "level":3},
    { key: 'rega1', label: 'REGA 1', section: ["feu"] , "freedate":true, "level":1},
    { key: 'rega2', label: 'REGA 2', section: ["feu"] , "freedate":true, "level":2},
    { key: 'reap', label: 'REAP', section: ["route"] ,"freedate":true, "level":1},
    { key: 'recp', label: ' RECP', section: ["route"] , "freedate":true, "level":2},
    { key: 'senemco1', label: 'SENAMCO Chef de groupe', section: ["territorial"] , "freedate":false, "level":1},
    { key: 'senemco2', label: 'SENAMCO Commissaire', section: ["territorial"] , "freedate":false, "level":2},
];
