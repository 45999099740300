import React, { useState, useEffect } from 'react';
import {branchOptions} from "../Utils/BranchOption"
import {

    Button,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    Card, CardContent,
    CircularProgress,

    Grid
} from '@mui/material';
import {useFetchWithToken} from "../hooks/useFetchWithToken";
import useTexts from "../hooks/useTexte";
import withAdminRole from "../hoc/withAdminRole";
function RulesPage() {
    const {t} = useTexts()

    const [rules, setRules] = useState([]);
    const [sectionRules, setSectionRules] = useState([]);
    const [rulesConfig, setRulesConfig] = useState({});
    const [loading, setLoading] = useState(true);
    const fetchWithToken = useFetchWithToken();


    useEffect(() => {
        const fetchRulesAndConfig = async () => {


            setLoading(true);
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/api/rules`;
                let rulesData = await fetchWithToken(apiUrl);

                setRules(rulesData);






                const apiUrl2 = `${process.env.REACT_APP_API_URL}/api/rules/section`;
                let sectionRulesData = await fetchWithToken(apiUrl2);

                setSectionRules(sectionRulesData);

                // Initialiser la configuration pour chaque règle dans chaque section
                const initRulesConfig = branchOptions.reduce((acc, branch) => {
                    const sectionKey = `section-${branch.id}`;
                    acc[sectionKey] = {};

                    rulesData.forEach(rule => {
                        const ruleKey = `rule-${rule.id}`;
                        // Initialisez toutes les règles comme non activées et non dérogatoires par défaut
                        acc[sectionKey][ruleKey] = { isEnabled: false, canDerogate: false };
                    });

                    return acc;
                }, {});

                // Mettre à jour la configuration avec les données de /rules/section
                sectionRulesData.forEach(({ section_id, rules_id, is_derogatoire }) => {
                    const sectionKey = `section-${section_id}`;
                    const ruleKey = `rule-${rules_id}`;
                    if (initRulesConfig[sectionKey] && initRulesConfig[sectionKey][ruleKey]) {
                        initRulesConfig[sectionKey][ruleKey] = { isEnabled: true, canDerogate: !!is_derogatoire };
                    }
                });

                setRulesConfig(initRulesConfig);

                setLoading(false);
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error);
                setLoading(false);
            }






        };

        fetchRulesAndConfig();
    }, []);


    const handleRuleToggle = (sectionId, ruleId) => {
        setRulesConfig(prevConfig => ({
            ...prevConfig,
            [`section-${sectionId}`]: {
                ...prevConfig[`section-${sectionId}`],
                [`rule-${ruleId}`]: {
                    ...prevConfig[`section-${sectionId}`][`rule-${ruleId}`],
                    isEnabled: !prevConfig[`section-${sectionId}`][`rule-${ruleId}`].isEnabled,
                },
            },
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();



        // Utilisez fetch ou Axios pour envoyer les données
        try {

            const apiUrl =`${process.env.REACT_APP_API_URL}/api/rules/section/save`;

            const body ={
                formData: (rulesConfig)
            }

            await fetchWithToken(apiUrl, "POST", body);

        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error);
            // Gérez l'erreur (par exemple, affichez un message d'erreur)
        }
    };
    const handleDerogationToggle = (sectionId, ruleId) => {
        setRulesConfig(prevConfig => ({
            ...prevConfig,
            [`section-${sectionId}`]: {
                ...prevConfig[`section-${sectionId}`],
                [`rule-${ruleId}`]: {
                    ...prevConfig[`section-${sectionId}`][`rule-${ruleId}`],
                    canDerogate: !prevConfig[`section-${sectionId}`][`rule-${ruleId}`].canDerogate,
                },
            },
        }));
    };

    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
        </Box>;
    }

    return (
        <Box sx={{ mt: 4, mx: 2 }}>
            {/*<Typography variant="h4" gutterBottom>{t("TEXT_RULES_DISPO")}</Typography>*/}
            <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container spacing={3}>
                    {branchOptions.map((branch, branchIndex) => (
                        <Grid item xs={12} md={6} lg={4} key={branch.id}>
                            <Card variant="outlined" sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" color="primary">{branch.label}</Typography>
                                    {rules.map((rule, ruleIndex) => {
                                        const sectionConfig = rulesConfig[`section-${branch.id}`];
                                        const ruleConfig = sectionConfig && sectionConfig[`rule-${rule.id}`];
                                        return (
                                            <Box key={rule.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                <Typography sx={{ flexGrow: 1 }}>
                                                    {rule.name}
                                                </Typography>
                                                <FormControlLabel
                                                    control={<Checkbox checked={ruleConfig?.isEnabled} onChange={(e) => handleRuleToggle(branch.id, rule.id, e, branchIndex, ruleIndex)} />}
                                                    label={t("TEXT_ACTIVER")}
                                                />
                                                {/*<FormControlLabel*/}
                                                {/*    control={<Checkbox checked={ruleConfig?.canDerogate} onChange={() => handleDerogationToggle(branch.id, rule.id)} disabled={!ruleConfig?.isEnabled} />}*/}
                                                {/*    label={t("TEXT_ALLOW_DEROGATION")}*/}
                                                {/*/>*/}
                                            </Box>
                                        );
                                    })}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>Envoyer</Button>
            </Box>
        </Box>
    );
}
export default withAdminRole(RulesPage);

