import React from 'react';
import {Modal, Typography, Box, Button} from '@mui/material';
import cetninquiet from './../../assets/cetninquiet.jpg';
import useTexts from "../../hooks/useTexte"; // Tell webpack this JS file uses this image


const ErrorModal = ({open, error, onClose}) => {
    const {t} = useTexts()
    const errorMessages = {
        have_same: t("ERROR_HAVE_SAME"),
        section_not_found: t("ERROR_SECTION_NOT_FOUND"),
        user_not_found: t("ERROR_USER_NOT_FOUND"),
        missing_field: t("ERROR_MISSING_FIELD"),
        techni_ko: t("ERROR_TECHNI_KO"),
        have_this_level: t("ERROR_HAVE_THIS_LEVEL"),
        no_function: t("NO_FUNCTION"),
        exist: t("ERROR_EXIST"),
    };

    const createMarkup = (message) => {
        return { __html: message };
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "60%",
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                textAlign: 'center'
            }}>
                <img src={cetninquiet} width={200}/>
                <Typography id="modal-description" sx={{mt: 2}}>
                    <div dangerouslySetInnerHTML={createMarkup(errorMessages[error])}/>

                </Typography>
                <Box sx={{mt: 2}}>
                    <Button variant="contained" onClick={onClose}>{t("BUTTON_FERMER")}</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ErrorModal;
