import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { branchOptions } from '../Utils/BranchOption';
import { LevelOptions } from '../Utils/LevelOption';
import withAdminRole from "../hoc/withAdminRole";

// Fonction pour transformer les données JSON
const prepareStats = (data, branches, levels) => {
    const stats = {};

    branches.forEach(branch => {
        const branchLevels = levels.filter(level => level.section.includes(branch.key))
            .map(level => level.key);

        stats[branch.label] = branchLevels.map(levelKey => {
            const levelData = data.find(item => item.name === branch.key && item.level === levelKey) || { count: 0 };
            return {
                level: LevelOptions.find(level => level.key === levelKey).label,
                count: levelData.count
            };
        });
    });

    return stats;
};

function KPIsPage() {
    const [stats, setStats] = useState({});

    useEffect(() => {
        // Simulez la récupération des données JSON
        const jsonData = [
            { name: "louvetisme", count: 10, level: "cep1" },
            { name: "eclaireur", count: 1, level: "cep1" },
            { name: "eclaireur", count: 1, level: "cep2" },
        ];

        const preparedStats = prepareStats(jsonData, branchOptions, LevelOptions);
        setStats(preparedStats);
    }, []);

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={3}>
                {Object.keys(stats).map((sectionName, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">{sectionName}</Typography>
                                {stats[sectionName].map((stat, statIndex) => (
                                    <Typography key={statIndex} variant="body1">
                                        {stat.level}: {stat.count} inscriptions
                                    </Typography>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
export default withAdminRole(KPIsPage);

