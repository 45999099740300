import React from 'react';
import { Box, Typography, Paper } from "@mui/material";
import useTexts from "../hooks/useTexte";
import { LevelOptions } from "../Utils/LevelOption";
import { branchOptions } from "../Utils/BranchOption";
import {  formatDate } from "../Utils/functionTransforms";

const AgendaItem = ({ formation }) => {
    const level = LevelOptions.find((branch) => formation.level === branch.key);
    const branch = branchOptions.find((branch) => formation.code === branch.key);
    const { t } = useTexts();

    return (
        <Paper elevation={1} sx={{ padding: '10px', borderRadius: '8px',}}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                {/* Logo Image */}
                {branch && branch.logo && (
                    <Box component="span" sx={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                        <img src={branch.logo} alt="Training" style={{ width: '50px', height: '50px', borderRadius: '8px' }} />
                    </Box>
                )}

                {/* Level and Branch Label */}
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginRight: '8px' }}>
                    {level.label} - {branch.label}
                </Typography>

                {/* Location */}
                <Typography variant="body2" sx={{ flexGrow: 1, marginRight: '8px' }}>
                    {formation.lieu}
                </Typography>

                {/* Date */}
                <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                    {t("TEXT_COL_DATE_FULL", formatDate(formation.start_date), formatDate(formation.end_date))}
                </Typography>
            </Box>
        </Paper>
    );
};

export default AgendaItem;
