import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../Context/AuthContext";
import { useSystem } from "../../../Context/System";

import useRoles from "../../../hooks/useRoles";
import useTexts from "../../../hooks/useTexte";

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import HomeIcon from '@mui/icons-material/HomeRounded';

import NavbarBlock from "../NavbarBlock";
import NavbarMobile from './NavbarMobile';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import SearchModal from "../../Search/SearchModal";
import { useFetchWithToken } from "../../../hooks/useFetchWithToken";
import './Navbar.css';

function Navbar() {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [isScrolled, setIsScrolled] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const { token, currentFunction, functionOptions, handeRunas, isRunAsFrom, handeRunasEnd } = useAuth();
    const { myFormationAsChef } = useSystem();
    const { t } = useTexts();
    const theme = useTheme();
    const navigate = useNavigate();
    const mediaquery = useMediaQuery(theme.breakpoints.down('sm'));
    const [dialogOpenSearch, setDialogOpenSearch] = useState(false)
    const ChefRoles = useMemo(() => ["CH", "COM", "ASCOM", "ASCBJ", "ASCBV", "ASCBR", "COMAD", "CADJ"], []);
    const ChefRolesLimited = useMemo(() => ["CH", "COM","ASCBJ", "ASCBV", "ASCBR"], []);

    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const roles = useRoles(chefFunction ? chefFunction.fonction : "");

    useEffect(() => {
        if (!mediaquery) {
            setIsNavCollapsed(true);
        }
    }, [mediaquery]);

    const userCanSeeSection = useCallback((sectionPermissions) => {
        if (!token) {
            return sectionPermissions.includes('guest');
        }
        return sectionPermissions.some(permission => roles.includes(permission));
    }, [token, roles]);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsScrolled(offset > 50);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const mesmaitriseItemVoeux = useMemo(() => ({ text: t("MENU_MAITRISES_VOEUX"), to: "/voir-voeux" }), [t]);
    const mesmaitriseItem = useMemo(() => ({ text: t("MENU_MAITRISES"), to: "/mes-maitrises" }), [t]);
    const mesmaitriseFormationItem = useMemo(() => ({ text: t("MENU_FOMRATION_MESTRES"), to: "/gerer-mes-ceps" }), [t]);

    const menuItems = useMemo(() => {
        const baseMenuItems = [
            { text: t("MENU_MES_VOEUX"), to: "/voeux", permissions: ["User"] },
            { text: t("MENU_MES_FORMATIONS"), to: "/formations", permissions: ["User"] },
            {
                text: t("MENU_FORMATION"),
                permissions: ["Administrator"],
                subItems: [
                    { text: t("MENU_MANAGE_FORMATION"), to: "/gerer-ceps", permissions: ["Administrator"] },
                    { text: t("MENU_MANAGE_DEROGATION"), to: "/gerer-derogation", permissions: ["Administrator"] },
                    { text: t("MENU_MANAGE_VOEUX"), to: "/gerer-voeux", permissions: ["Administrator"] },
                    { text: t("MENU_MANAGE_QUESTIONAIRES"), to: "/template", permissions: ["Administrator"] },
                ]
            },
            { text: t("MENU_FORMATION_RESP"), to: "/gerer-ceps", permissions: ["Responsable_formation"] },
            { text: t("MENU_MANAGE_DEROGATION"), to: "/gerer-derogation", permissions: ["Responsable_formation"] },
            {
                text: t("MENU_CONFIGURATION_SYSTEM"),
                permissions: ["Administrator"],
                subItems: [
                    { text: t("MENU_TEXTEEDITOR"), to: "/texteditor", permissions: ["Administrator"] },
                    { text: t("MENU_MANAGE_CONFIGURATION"), to: "/configuration", permissions: ["Administrator"] },
                    { text: t("MENU_RULES"), to: "/rules", permissions: ["Administrator"] },
                    { text: t("MENU_EMAIL"), to: "/email", permissions: ["User", "Administrator"] },
                ]
            },
            { text: t("MENU_USER"), to: "/gerer-utilisateur", permissions: ["Administrator"] },
            { text: t("FORMATION_CALENDAR"), to: "/agenda", permissions: ["Administrator"] },
        ];

        if (userCanSeeSection(["Administrator"]) && !isRunAsFrom) {
            baseMenuItems.push({
                text: t('SWITCH_USER'),
                to: "#",
                permissions: ["Administrator"],
                onClick: () => setDialogOpenSearch(true),
            });
        }
        if (chefFunction && ChefRoles.includes(chefFunction.fonction)) {
            baseMenuItems.push({
                text: t('MENU_MAITRISES'),
                to: mesmaitriseItem.to,
                permissions: ["User"],
            });
        }
        if (chefFunction && ChefRolesLimited.includes(chefFunction.fonction)) {
            baseMenuItems.push({
                text: t('MENU_MAITRISES_VOEUX'),
                to: mesmaitriseItemVoeux.to,
                permissions: ["User"],
            });
        }
        if (myFormationAsChef && myFormationAsChef.length > 0) {
            baseMenuItems.push({
                text: t('MENU_FOMRATION_MESTRES'),
                to: mesmaitriseFormationItem.to,
                permissions: ["User"],
            });
        }
        if (userCanSeeSection(["User"]) && isRunAsFrom) {
            baseMenuItems.push({
                text: t('SWITCH_USER_END'),
                to: "#",
                permissions: ["User"],
                onClick: () => handeRunasEnd(),
            });
        }

        return baseMenuItems;
    }, [t, userCanSeeSection, isRunAsFrom, setDialogOpenSearch]);

    const handleNavCollapse = useCallback(() => setIsNavCollapsed(!isNavCollapsed), [isNavCollapsed]);

    const handleMouseEnter = useCallback((index) => {
        if (index >= 0) {
            setOpenDropdown(index);
        }
    }, []);
    const handleMouseLeave = useCallback(() => {
        setOpenDropdown(null);
    }, []);

    const renderMenuItems = useCallback((items) => {
        if (!items) {
            return null;
        } else if (!items.length) {
            return null;
        }
        return items.filter(({ permissions }) => userCanSeeSection(permissions)).map((item, index) => {
            const isOpen = openDropdown === index;
            return (
                <li
                    key={item.to}
                    className={`dropdown ${item.subItems ? 'dropdownMenu' : ''} ${isOpen ? 'show' : ''}`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    {item.subItems ? (
                        <div className='dropdownSubItems'>
                            <a key={item.text} className="dropdown-toggle" role="button">
                                {item.text} <span className="caret"></span>
                            </a>
                            <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
                                {item.subItems.filter(({ permissions }) => userCanSeeSection(permissions)).map((subItem) => (
                                    <div key={subItem.to} className="dropdown-subitems-container" onClick={() => {
                                        handleMouseLeave();
                                        setIsNavCollapsed(true);
                                    }}>
                                        <NavbarBlock item={subItem} />
                                    </div>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <NavbarBlock item={item} />
                    )}
                </li>
            );
        });
    }, [userCanSeeSection, openDropdown, handleMouseEnter, handleMouseLeave]);

    const CollapsibleMenu = ({ isNavCollapsed, menuItems, ...props }) => {
        return (
            !isNavCollapsed && (
                <div className='navbar-sm'>
                    {renderMenuItems(menuItems)}

                </div>
            )
        );
    };

    function DesktopNavbar({
        menuItems,
        chefFunction,
        ChefRoles,
        ChefRolesLimited,
        mesmaitriseItem,
        mesmaitriseFormationItem,
        myFormationAsChef, isRunAsFrom
    }) {
        return (
            <div className="navbar-classic">
                <div className='dropdownMenuIconPetit' onClick={() => navigate("/")}>
                    <HomeIcon style={{ cursor: 'pointer' }} />
                </div>
                {renderMenuItems(menuItems)}

                {/* Moved in renderMenuItems
                {chefFunction && ChefRoles.includes(chefFunction.fonction) && (
                    <NavbarBlock key="mymatrese" item={mesmaitriseItem} />
                )}
                {chefFunction && ChefRolesLimited.includes(chefFunction.fonction) && (
                    <NavbarBlock key="mymatresevoeux" item={mesmaitriseItemVoeux} />
                )}
                {myFormationAsChef && myFormationAsChef.length > 0 && (
                    <NavarBlock key="mesFormations" item={mesmaitriseFormationItem} />
                )}
                {
                    isRunAsFrom && (

                        <div className={`dropdown dropdownMenu `} onClick={event => handeRunasEnd()}
                            style={{ cursor: 'pointer' }}>
                            <a data-toggle="dropdown" data-submenu="">{t('SWITCH_USER_END')}</a>
                        </div>

                    )
                }*/}
            </div>
        )
            ;
    }

    return (
        <div className="navbar-container">
            <nav className={`navbar-default navbar-fixed-width ${isScrolled ? 'navbar-fixed-top' : ''}`}>
                <NavbarMobile isNavCollapsed={isNavCollapsed} handleNavCollapse={handleNavCollapse} />
                <DesktopNavbar
                    menuItems={menuItems}
                    chefFunction={chefFunction}
                    ChefRoles={ChefRoles}
                    ChefRolesLimited={ChefRolesLimited}
                    mesmaitriseItem={mesmaitriseItem}
                    mesmaitriseFormationItem={mesmaitriseFormationItem}
                    myFormationAsChef={myFormationAsChef}
                    isRunAsFrom={isRunAsFrom}
                />
                <CollapsibleMenu
                    isNavCollapsed={isNavCollapsed}
                    menuItems={menuItems}
                    mesmaitriseItem={mesmaitriseItem}
                    mesmaitriseFormationItem={mesmaitriseFormationItem}
                    chefFunction={chefFunction}
                    myFormationAsChef={myFormationAsChef}

                />
            </nav>
            <Dialog open={dialogOpenSearch} onClose={() => setDialogOpenSearch(false)}
                aria-labelledby="search-dialog-title">


                <DialogTitle id="search-dialog-title">{t("FIELD_MESTRE_CAMP")}</DialogTitle>
                <DialogContent>
                    <SearchModal onClick={(data) => handeRunas(data, setDialogOpenSearch)} functionMode={null} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Navbar;
