import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAuth } from "../Context/AuthContext";
import {jwtDecode } from 'jwt-decode';

import Modal from 'react-modal';
import './styles/OAuthCallback.css'; // Assurez-vous de créer ce fichier CSS pour ajouter du style

const OAuthCallback = () => {
    let navigate = useNavigate();
    const { login } = useAuth();
    const { provider } = useParams();
    const location = useLocation();

    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempToken, setTempToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Pour indiquer qu'un compte est en cours de traitement

    useEffect(() => {
        const fetchToken = async () => {
            const params = new URLSearchParams(window.location.search);
            const code = params.get('code');
            const state = params.get('state');
            if (code && provider) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/${provider}/callback?code=${code}`);
                    const data = await response.json();
                    if (response.ok) {
                        const token = data.token;
                        const decodedToken = jwtDecode(token);

                        if (decodedToken.users) {
                            // S'il y a plusieurs comptes, afficher la modal de sélection
                            setTempToken(token); // Stocker le token temporaire
                            setUsers(decodedToken.users); // Stocker la liste des utilisateurs
                            setIsModalOpen(true); // Ouvrir la modal
                        } else {
                            // Si un seul compte, se connecter directement
                            login(data.token);
                            if (state) {
                                navigate(state, { replace: true });
                            } else {
                                navigate('/');
                            }
                        }
                    } else {
                        navigate('/login?error=' + data.message);
                    }
                } catch (error) {
                    console.error('Error during OAuth callback:', error);
                    navigate('/login?error=_error_');
                }
            } else {
                navigate('/login?error=_error_');
            }
        };

        fetchToken();
    }, [login, navigate, provider]);

    // Fonction pour générer le token définitif après la sélection de l'utilisateur
    const handleUserSelection = async (userId) => {
        setIsLoading(true); // Indiquer que le processus de connexion est en cours
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/generate-token`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${tempToken}`, // Utiliser le token temporaire ici
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }) // Envoyer l'ID de l'utilisateur sélectionné
            });
            const data = await response.json();
            if (response.ok) {
                // Connexion avec le nouveau token
                login(data.token);
                if (location.state) {
                    navigate(location.state, { replace: true });
                } else {
                    navigate('/');
                }
            } else {
                console.error('Error generating final token:', data.message);
                setIsLoading(false); // Arrêter le chargement en cas d'erreur
            }
        } catch (error) {
            console.error('Error during user selection:', error);
            setIsLoading(false); // Arrêter le chargement en cas d'erreur
        }
    };

    return (
        <div>
            <p>Loading...</p>
            {/* Modal pour sélectionner l'utilisateur si plusieurs comptes existent */}
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    contentLabel="Select Account"
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <h2 className="modal-title">Plusieurs comptes associés à cet email</h2>
                    <p className="modal-description">
                        Il semble que plusieurs comptes soient associés à cet email. Veuillez sélectionner le compte avec lequel vous souhaitez vous connecter.
                    </p>
                    <ul className="user-list">
                        {users.map((user) => (
                            <li key={user.id} className="user-list-item">
                                <button
                                    className="user-select-button"
                                    onClick={() => handleUserSelection(user.id)}
                                    disabled={isLoading} // Désactiver les boutons pendant le chargement
                                >
                                    {user.firstname} {user.lastname} (Code Sycomore: {user.codeSycomore})
                                </button>
                            </li>
                        ))}
                    </ul>
                    {isLoading && <p className="loading-message">Connexion en cours...</p>} {/* Afficher un message de chargement */}
                </Modal>
            )}
        </div>
    );
};

export default OAuthCallback;
