import React from 'react'
import HeaderLogo from '../HeaderLogo'
import HeaderProfile from '../HeaderProfile'
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

export default function NavbarMobile({isNavCollapsed, handleNavCollapse}) {
    return (
        <div className="mobile-version">
            <button type="button" className="navbar-toggle" onClick={handleNavCollapse}>
                {isNavCollapsed ? <MenuIcon/> : <MenuOpenIcon/>}
            </button>
            <HeaderLogo/>
            <HeaderProfile/>
        </div>
    )
}
