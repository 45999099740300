import React, {useEffect, useState} from 'react';
import {
    Box,

    Button, Card, CardContent, CardMedia, Dialog, DialogContent, DialogTitle, Grid, Typography,

} from '@mui/material';
import {formatDate} from '../../Utils/functionTransforms';
import {StatusFormationMapping} from '../../Utils/StatusMapping'
import {useFetchWithToken} from "../../hooks/useFetchWithToken"
import FormationUserList from "./FormationUserList";

import {LevelMapping} from "../../Utils/LevelMapping";
import useTexts from "../../hooks/useTexte";
import {useNavigate} from "react-router-dom";
import {LevelOptions} from "../../Utils/LevelOption";
import {branchOptions} from "../../Utils/BranchOption";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import CircularIcon from '@mui/icons-material/Description';
import QuestionnaireIcon from '@mui/icons-material/ListAlt';
import ListIcon from '@mui/icons-material/List';
import {useConfirm} from "../../Provider/ConfirmProvider";
import StagiareCard from "./StagiareCard";
import * as PropTypes from "prop-types";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import DocumentCard from "../Core/DocumentCard";
import {useAuth} from "../../Context/AuthContext";
import {downloadDocument} from "../../Utils/downloadDocument";
import CustomFormBuilder from "../customFormBuilder/CustomFormBuilder";


const FormationMangerItem = ({formation, index, functionOptions, addFormation, questionnaires, tasks}) => {
    const fetchWithToken = useFetchWithToken();
    const {token,} = useAuth()

    const [formationLocal, setFormationLocal] = useState(formation)
    const [openModalList, setOpenModalList] = useState(false);
    const [users, setUsers] = useState([]);
    const [keyModal, setKeyModal] = useState(0); // Utilisé pour forcer la recréation de FormBuilder
    const [initialFormData, setInitialFormData] = useState(null);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [openListModal, setOpenListModal] = useState(false);
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [hasUnsavedChangesFiche, setHasUnsavedChangesFiche] = useState(false);
    const {requestConfirm} = useConfirm(); // Utiliser le hook pour obtenir la fonction de demande de confirmation
    const [nbrStagiaire, setNbrStagiaire] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [attachements, setAttachements] = useState([]);
    const {t} = useTexts();
    const statusIcons = {
        "open": {"icon": <CheckCircleIcon color="success"/>, label: t("OPEN_FORMATION_LABEL")},
        "selecting": {"icon": <CheckCircleIcon color="success"/>, label: t("OPEN_FORMATION_LABEL")},
        "selected": {"icon": <CheckCircleIcon color="success"/>, label: t("OPEN_FORMATION_LABEL")},
        "draft": {"icon": <CheckCircleIcon color="warning"/>, label: t("PROJET_FORMATION_LABEL")},

    };



    const handleExportPDF = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/pdf`;

            const response = await fetchWithToken(apiUrl, "POST", {}, false, "RESPONSE");

            // Vérifier si la réponse est correcte
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération du fichier.');
            }

            // Récupérer le blob depuis la réponse
            const blob = await response.blob();

            // Récupérer l'en-tête Content-Disposition
            const contentDisposition = response.headers.get('Content-Disposition');
            console.log(response.headers)
            // Extraire le nom de fichier depuis l'en-tête (si présent)
            let fileName = ''; // Nom par défaut
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);


                if (fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
        }
    };
    const fetchData = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/data`;
            const data = await fetchWithToken(apiUrl, "POST", {});
            setNbrStagiaire(data.nbrStgaire);
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'image :', error);
        }
    };
    useEffect(() => {
        fetchData()
    }, []); // Re-run effect when path or authToken changes


    const handleCloseDocumentsModal = () => {
        setOpenDocumentsModal(false);
    };
    const handleOpenDocumentsModal = async () => {
        const apiUrlDocuments = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/documents`;
        try {
            const data = await fetchWithToken(apiUrlDocuments);
            setDocuments(data);
            setOpenDocumentsModal(true);
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };
    const handleOpenQuestionaire= async () => {
        const apiUrlDocuments = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/openfiche`;
        try {
            const data = await fetchWithToken(apiUrlDocuments);
            setFormationLocal((prevFormationLocal) => ({
                ...prevFormationLocal,
                open_questionaire: 1, // Remplacez 'status' et 'data.newStatus' par la propriété et la nouvelle valeur appropriées
            }));
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };


    const handleFileUpload = async () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const apiUrlUpload = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/document`;
        try {
            await fetchWithToken(apiUrlUpload, 'POST', formData, 1);
            handleOpenDocumentsModal(); // Refresh the documents list
        } catch (error) {
            console.error("Error uploading document:", error);
        }
    };
    const handleExportExcel = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/answer?format=pdf`;


            // Exemple de données à envoyer au backend (formData)
            const formData = {


            };

            const blob = await fetchWithToken(apiUrl, "POST", formData, false, "BLOB");


            // Téléchargement du fichier PDF
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
        }
    };

    const handleDeleteDocument = async (documentId) => {
        const apiUrlDelete = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/document/${documentId}`;
        try {
            await fetchWithToken(apiUrlDelete, 'DELETE' , {});
            handleOpenDocumentsModal(); // Refresh the documents list
        } catch (error) {
            console.error("Error deleting document:", error);
        }
    };

    const handleDownloadDocument = (filename) => {
        downloadDocument(filename, token, {"type": "mestre", "formationId": formation.id})

    };

    const listUserSelecing = async () => {
        try {
            const apiUrlAttachement = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${formation.id}/attachements`;
            await fetchWithToken(apiUrlAttachement).then(data => {
                setAttachements(data)

            });

            const apiUrl = `${process.env.REACT_APP_API_URL}/api/fomrationsadmin/user/${formation.id}/selected`;
            await fetchWithToken(apiUrl).then(data => {
                setUsers(data)
                setOpenListModal(true);
            });


        } catch (error) {
        } finally {
        }

    };
    const handleOpenFormModal = (id) => {
        setKeyModal(keyModal + 1)
        setInitialFormData(id);
        setOpenFormModal(true);
    };

    const handleOpenListModal = (id) => {
        listUserSelecing();
    };


    const level = LevelOptions.find((branch) => formation.level === branch.key)
    const branch = branchOptions.find((branch) => formation.section_id === branch.id)
    const handleCloseFormModal = () => {

        if (hasUnsavedChangesFiche) {
            // Demander la confirmation si des modifications non sauvegardées sont présentes
            requestConfirm(t("MODIFICATION_LOST_WARNING"), () => {
                setOpenFormModal(false); // Fermer le modal si l'utilisateur confirme
            });
        } else {
            // Fermer le modal directement si aucune modification n'est détectée
            setOpenFormModal(false);
        }
        setHasUnsavedChangesFiche(false)


    };
    const handleCloseListModal = () => {
        // Fermer le modal directement si aucune modification n'est détectée
        setOpenListModal(false);
    };

    useEffect(() => {
        //loadFormation();
        // listUserSelecing();
    }, []); // Le tableau vide assure que l'effet s'exécute une seule fois au montage
    return (
        <>
            <Card sx={{
                maxWidth: 345,
                margin: 2,
                textAlign: 'center',
                border: '2px solid #ccc',
                borderRadius: '10px',
                overflow: 'visible'
            }}>
                <CardMedia
                    component="img"
                    sx={{width: '80px', height: '80px', margin: '-25px auto 10px', zIndex: 1, position: 'relative'}}
                    image={branch.logo}

                />
                <CardContent>
                    <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "30px"}} gutterBottom
                                variant="h5"
                                component="div">{level.label}</Typography>
                    <Typography sx={{fontFamily: 'Ubuntu', fontWeight: "bold", fontSize: "24px"}} variant="h6"
                                component="div">{formation.nom}</Typography>
                    <Typography sx={{fontFamily: 'Ubuntu'}} variant="body1" color="text.secondary">

                        <div>du {formatDate(formation.start_date)}</div>
                        <div>au {formatDate(formation.end_date)}</div>
                    </Typography>

                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0'}}>
                        {statusIcons[formation.status].icon}<Typography variant="body1"
                                                                        sx={{marginLeft: 1}}>{statusIcons[formation.status].label}</Typography>
                    </Box>
                    {nbrStagiaire !== false  && (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0'}}>
                        {t('NB_STAGIAIRES_IN_THIS_FORM', nbrStagiaire)}
                    </Box>
                    )}
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', textAlign: "left"}}>
                        <Button variant="contained" startIcon={<CircularIcon/>} sx={{width: '100%'}}
                                onClick={handleOpenDocumentsModal}>{t("DOC_STAGIAIRE")}</Button>
                        <Button variant="contained" onClick={() => handleOpenListModal()} startIcon={<ListIcon/>}
                                sx={{width: '100%'}}>{t("LIST_STAGIAIRE")}</Button>

                        {formationLocal.questionaire_id != 0 && (
                            <>

                                <Button variant="contained"
                                        onClick={() => handleOpenFormModal(formation.questionaire_id)}
                                        startIcon={<QuestionnaireIcon/>}
                                        sx={{width: '100%'}}>{t("QUESTIONAIRE_STAGIAIRE")}</Button>

                                {formationLocal.open_questionaire == 0 && (
                                    <Button variant="contained"
                                            onClick={() => handleOpenQuestionaire()}
                                            startIcon={<QuestionnaireIcon/>}
                                            sx={{width: '100%'}}>{t("QUESTIONAIRE_STAGIAIRE_OPEN")}</Button>
                                )}
                            </>
                        )}
                    </Box>
                </CardContent>

            </Card>

            <Dialog open={openFormModal} onClose={handleCloseFormModal} maxWidth="lg" fullWidth>
                <DialogTitle>Modifier la Fiche</DialogTitle>
                <DialogContent>
                    <CustomFormBuilder key={keyModal} initialData={initialFormData} isModal={true}
                                 closeModal={handleCloseFormModal}
                                 setHasUnsavedChangesParent={setHasUnsavedChangesFiche}/>
                </DialogContent>
            </Dialog>

            <Dialog open={openListModal} onClose={handleCloseListModal} maxWidth="lg" fullWidth>
                <DialogContent>
                    <Grid container spacing={2}>
                        {users && attachements && users.map((user, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <StagiareCard key={index} user={user}
                                              attachments={attachements.filter((attachement => attachement.formation_user_id === user.formation_user_id))}/>
                            </Grid>
                        ))}

                        <Button onClick={() => handleExportExcel()} color="primary">
                            Exporter en Excel
                        </Button>
                        <Button onClick={() => handleExportPDF()} color="primary">
                            Exporter toutes les fiches stagaires
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog open={openDocumentsModal} onClose={handleCloseDocumentsModal} maxWidth="lg" fullWidth>
                <DialogTitle>Documents</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {documents.map((doc) => (
                            <Grid item xs={12} sm={6} md={4} key={doc.id}>
                                <DocumentCard document={doc}
                                              onDelete={() => handleDeleteDocument(doc.id)}
                                              onDownload={() => handleDownloadDocument(doc.path)}/>
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{marginTop: 2}}>
                        <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])}/>
                        <Button variant="contained" startIcon={<CloudUploadIcon/>} onClick={handleFileUpload}
                                disabled={!selectedFile}>
                            Upload
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
        ;
};

export default FormationMangerItem;
