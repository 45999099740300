import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Snackbar,
} from '@mui/material';
import { useFetchWithToken } from '../hooks/useFetchWithToken';
import withAdminRole from "../hoc/withAdminRole";
import { Table } from 'react-bootstrap';
import useTexts from '../hooks/useTexte';

const ConfigEditor = () => {
    const [texts, setTexts] = useState([]);
    const [editText, setEditText] = useState({ id: '', key: '', text: '' });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { t } = useTexts();
    const fetchWithToken = useFetchWithToken();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    const fetchTexts = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/configuration`;
            const data = await fetchWithToken(apiUrl, "GET");
            setTexts(data);
        } catch (error) {
            console.error(error);
        }
    };

    const updateText = async (key, text) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/configuration/`;
            const updatedTextData = await fetchWithToken(apiUrl, 'PUT', { key, text });
            setTexts(texts.map(t => t.key === key ? updatedTextData : t));
            setIsDialogOpen(false);
            setSnackbarOpen(true);
            setSnackMessage('Texte modifié avec succès');
        } catch (error) {
            console.error(error);
            setSnackbarOpen(true);
            setSnackMessage('Erreur lors de la modification du texte');
        }
    };

    const handleDialogOpen = (text) => {
        setEditText(text);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogSave = () => {
        updateText(editText.id, editText.KEY, editText.value);
    };

    useEffect(() => {
        fetchTexts();
    }, []);

    return (
        <Container>
            <Typography variant="h4" align='center' gutterBottom >{t('TITRE_CONFIGURATION_SITE')}</Typography>
            <TableContainer sx={{ maxHeight: '100%' }} className='desktop-restrict'>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Value</TableCell>
                            <TableCell style={{ fontWeight: 'bold', width: '100px' }}>Description</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {texts.map(text => (
                            <TableRow key={text.id}>
                                <TableCell align='left'>{text.description}</TableCell>
                                <TableCell align='left'>{text.value}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleDialogOpen(text)}
                                    >
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog fullWidth open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Text</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Modify the text and key as needed.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        label="Text"
                        fullWidth
                        value={editText.value}
                        onChange={e => setEditText({ ...editText, value: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Annuler
                    </Button>
                    <Button onClick={handleDialogSave} color="primary">
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackMessage}
            />
        </Container>
    );
};

export default withAdminRole(ConfigEditor);
