import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Tooltip, IconButton, Badge } from '@mui/material';
import { CheckCircle, Cancel, Info } from '@mui/icons-material';
import { useFetchWithToken } from "../../hooks/useFetchWithToken";
import { useNavigate } from "react-router-dom";
import StructureItemChef from "./StructureItemChef";
import FeedbackFormModal from "../Feedback/FeedbackFormModal";
import FeedbackForm from "../Feedback/FeedbackForm";
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const StructureItem = ({ structure, current, list, callBacks, size }) => {
    const [chefs, setChefs] = useState([]);
    const fetchWithToken = useFetchWithToken();
    const navigate = useNavigate();
    const hasChild = list.filter(u => u.parent_id === structure.id).length > 0;
    const [modalAvisEdit, setModalAvisEdit] = useState(false);
    const [openModalAvis, setOpenModalAvis] = useState(false);
    const [currentAvis, setCurrentAvis] = useState(null);
    const [selectedChefAvis, setSelectedChefAvis] = useState(null);
    const [selectedChefType, setSelectedChefType] = useState(null);
    const [showChef, setShowChef] = useState(size == 1 ? true : false);

    useEffect(() => {
        if (selectedChefAvis) {
            setOpenModalAvis(true);
        }
    }, [selectedChefAvis]);

    const handleCloseModalAvis = () => {
        setOpenModalAvis(false);
        setSelectedChefAvis(null);
    };

    const openModalAvisCallBack = (chef, editable, current, type) => {
        if (current || editable) {
            setModalAvisEdit(editable);
            setCurrentAvis(current);
            setSelectedChefAvis(chef);
            setSelectedChefType(type)
        }
    };

    const handleNavigation = () => {
        if (hasChild)
            navigate(`/mes-maitrises/${structure.id}`);
    };

    async function loadChefs() {
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/unite/${structure.id}/maitrise`;
            const data = await fetchWithToken(apiUrl);
            setChefs(data.chefs);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        loadChefs();
    }, []);

    const sortedChefs = chefs.sort((a, b) => {
        // Prioritize chefs with 'ch' or 'com' in fonction
        if (a.fonction === 'CH' || a.fonction === 'COM') return -1;
        if (b.fonction === 'CH' || b.fonction === 'COM') return 1;
        return 0;
    });

    return (
        <Card sx={{ borderRadius: 2, boxShadow: 10, my: 2 }} >
            <CardContent className='maitrise-deroulant'>
                <Typography variant="h6" onClick={handleNavigation}
                    sx={{
                        cursor: hasChild ? 'pointer' : 'default',
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Add this line to center the content horizontally
                        textAlign: 'left', // Add this line to center the text
                        marginBottom: 0,
                    }}>
                    {structure.nom}
                    <IconButton onClick={() => setShowChef(!showChef)}>
                        {showChef ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                </Typography>
                {showChef && (
                    <Grid container sx={{ display: 'flex', flexDirection: 'column' }} >
                        {sortedChefs.map((chef, index) => (
                            <Grid item xs={12} sm={12} md={12} key={index}>
                                <StructureItemChef
                                    key={index}
                                    structure={structure}
                                    chef={chef}
                                    index={index}
                                    callBacks={{ openModalAvisCallBack, loadChefs }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </CardContent>
            <FeedbackFormModal open={openModalAvis} onClose={handleCloseModalAvis}>
                <FeedbackForm
                    chef={selectedChefAvis}
                    structure={structure}
                    onClose={handleCloseModalAvis}
                    editable={modalAvisEdit}
                    loadChef={loadChefs}
                    currentAvis={currentAvis}
                    selectedChefType={selectedChefType}
                />
            </FeedbackFormModal>
        </Card>
    );
};

export default StructureItem;
