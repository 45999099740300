import React, {useState, useEffect} from 'react';
import {useFetchWithToken} from "../../hooks/useFetchWithToken";

const ImageSecure = ({path, params}) => {
    const [imageUrl, setImageUrl] = useState('');
    const fetchWithToken = useFetchWithToken();

    useEffect(() => {
        const fetchImage = async () => {


            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}/images/${path}`;
                const imageData = await fetchWithToken(apiUrl, "POST", params, false, "TEXT");
                setImageUrl(imageData);
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'image :', error);
            }
        };

        fetchImage();

        // Clean up function to revoke the object URL to avoid memory leaks
        return () => URL.revokeObjectURL(imageUrl);
    }, [path]); // Re-run effect when path or authToken changes

    return (
        <div>
            {imageUrl && (
                <img src={imageUrl} alt="Uploaded" style={{width: '100px', marginBottom: '8px'}}/>
            )}
        </div>
    );
};

export default ImageSecure;
