import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, Card, CardContent, Button } from '@mui/material';
import DerogationItem from "../Wish/DerogationItem";
import FeedbackFormModal from "../Feedback/FeedbackFormModal";
import FeedbackForm from "../Feedback/FeedbackForm";

// Composant principal
const UserFunction = ({ u, loadChef, isPage }) => {
    const [selectedChefAvis, setSelectedChefAvis] = useState(null);
    const [selectedChefType, setSelectedChefType] = useState(null);
    const [openModalAvis, setOpenModalAvis] = useState(false);
    const [currentAvis, setCurrentAvis] = useState(false);

    // Gestion de l'ouverture/fermeture de la modal d'avis
    const handleCloseModalAvis = () => {
        setOpenModalAvis(false);
        setSelectedChefAvis(null);
    };

    // Extraction des avis
    const avisChef = u.avis.find(avis => avis.avis_level === 1);
    const avisChef2 = u.avis.find(avis => avis.avis_level === 2);
    const avisPeda = u.avis.find(avis => avis.avis_is_peda === 1);

    // Variables indiquant la présence de chefs
    const haveChef = u.chef === 1;
    const haveChef2 = u.chefGp === 1;
    const haveChef3 = u.chefAscom === 1;

    // Fonction pour ouvrir la modal d'avis avec les informations appropriées
    const openModalAvisCallBack = (type, current) => {
        setCurrentAvis(current);
        setSelectedChefAvis(u);
        setSelectedChefType(type);
        setOpenModalAvis(true);
    };

    return (
        <Box display="flex" flexDirection="column" gap={2} p={2} width="100%">
            <UserInfo u={u} />

            <Grid container spacing={2}>
                <AvisBoxGrid
                    title="Avis 1"
                    avis={avisChef}
                    type={1}

                    isPage={isPage}
                    haveChef={haveChef}
                    onClick={openModalAvisCallBack}
                />
                <AvisBoxGrid
                    title="Avis 2"
                    avis={avisChef2}
                    type={2}
                    isPage={isPage}

                    haveChef={haveChef2}
                    onClick={openModalAvisCallBack}
                />
                <AvisBoxGrid
                    title="Avis pédagogique"
                    avis={avisPeda}
                    type={3}
                    isPage={isPage}
                    haveChef={haveChef3}
                    onClick={openModalAvisCallBack}
                />
            </Grid>

            {/* Section des voeux */}
            <VoeuxList voeux={u.voeux} />

            {/* Modal d'avis */}
            <FeedbackFormModal open={openModalAvis} onClose={handleCloseModalAvis}>
                <FeedbackForm
                    chef={u}
                    diff={selectedChefType}
                    isAdmin={1}
                    editable={1}
                    loadChef={loadChef}
                    currentAvis={currentAvis}
                    onClose={handleCloseModalAvis}
                />
            </FeedbackFormModal>
        </Box>
    );
};

// Composant affichant les informations de l'utilisateur
const UserInfo = ({ u }) => (
    <Box mb={2}>
        <Typography variant="h6">{u.nom}</Typography>
        <Typography variant="subtitle1" color="textSecondary">{u.fonction}</Typography>
    </Box>
);

// Composant affichant les avis avec gestion des chefs
const AvisBoxGrid = ({ title, avis, type, haveChef, onClick, isPage }) => (
    <Grid item xs={12} sm={4}>
        <AvisBox isPage={isPage} title={title} avis={avis} type={type} onClick={haveChef ? onClick : null} />
    </Grid>
);

// Composant affichant chaque avis
const AvisBox = ({ title, avis, onClick, type, isPage }) => (
    <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="subtitle1">{title}</Typography>
        <Box
            mt={1}
            p={1}
            maxHeight="150px"
            overflow="auto"
            border="1px solid #e0e0e0"
            borderRadius="4px"
            bgcolor="#f5f5f5"
        >
            <Typography variant="body1">
                {avis ? (avis.idontknow === 1 ? 'Je ne connais pas le chef' : avis.avis_avis) : 'Pas d\'avis'}
            </Typography>
        </Box>

        {/* Bouton placé sous la box */}
        {onClick && isPage && (
            <Box mt={2} display="flex" justifyContent="center">
                <Button variant="contained" onClick={() => onClick(type, avis)}>
                    Modifier
                </Button>
            </Box>
        )}
    </Paper>
);

// Composant affichant la liste des voeux
const VoeuxList = ({ voeux }) => (
    <Grid item xs={12}>
        {voeux.map((wish, index) => (
            <Card key={wish.voeu_id} sx={{ width: '100%', marginBottom: 2 }}>
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <DerogationItem wish={wish} ficheMode={1} />
                </CardContent>
            </Card>
        ))}
    </Grid>
);

export default UserFunction;
