import React from 'react';
import '././../../themes/animateButton.css';
import DeleteIcon from '@mui/icons-material/Delete';

import './styles/AnimateButton.css';

const AnimateButton = ({type}) => {
    const style = {
        cursor: "pointer",
        color: "#B71234",
        fontSize: "32px",
        alignItems: "center",
        textAlign: "center",
        padding: "8px",
        backgroundColor: 'white',
        borderRadius: "16%",
    }
    return (
        <div className='deleteWish' style={style}>
            <DeleteIcon/>
        </div>
    );
};

export default AnimateButton;
