import React from 'react';
import {CardContent, Typography, Grid, Button, Card, Box, Divider} from '@mui/material';
import useTexts from "../../hooks/useTexte";
import { useAuth } from "../../Context/AuthContext";
import { LevelOptions } from "../../Utils/LevelOption";
import { functionMapping } from "../../Utils/functionMapping";
import StructureItemListFormation from "./StructureItemListFormation";

import './styles/StructureItemChef.css';

const StructureItemChef = ({structure, chef, index, callBacks, size}) => {
    const {t} = useTexts();
    const allowRules = ["CH", "COM", "COMAD", "CADJ"];
    const avisChef = chef.avis.find(avis => avis.avis_level === 1);
    const avisChef2 = chef.avis.find(avis => avis.avis_level === 2);
    const avisPeda = chef.avis.find(avis => avis.avis_is_peda === 1);
    const {currentFunction, functionOptions} = useAuth();
    const chefFunction = functionOptions.find(func => func.id == currentFunction);
    const isPedaWrite =
        (structure.section_id === 1 && chefFunction.fonction === 'ASCBJ') ||
        (structure.section_id === 2 && chefFunction.fonction === 'ASCBV') ||
        (structure.section_id === 3 && chefFunction.fonction === 'ASCBV') ||
        (structure.section_id === 4 && chefFunction.fonction === 'ASCBR') ||
        (structure.section_id === 5 && chefFunction.fonction === 'ASCBR');

    const getMappedFunction = (functionCode) => {
        return functionMapping[functionCode] || functionCode;
    };

    const getFirstButtonText = () => {
        if (structure.type === "Unité Locale") return "Avis du CG";
        if (["Unité Territoriale", "Unité Territoriale facturable", "Groupe"].includes(structure.type)) return "Avis du CD";
        if (structure.type === "District") return "Avis du CPr";
        return "";
    };

    const getFirstButtonColor = () => {
        if (structure.diff === 1) {
            return avisChef ? "success" : "warning";
        }
        return avisChef ? "success" : "warning";
    };

    const getFirstButtonLabel = () => {
        if (structure.diff === 1) {
            return avisChef ? "Modifier" : "Ajouter";
        }
        return avisChef ? "Consulter" : "Non disponible";
    };

    const getSecondButtonText = () => {
        if (structure.type === "Unité Locale") return "Avis du CD";
        if (["Unité Territoriale", "Unité Territoriale facturable", "Groupe"].includes(structure.type)) return "Avis du CPr";
        return "";
    };

    const getSecondButtonColor = () => {
        if (structure.diff === 2) {
            return avisChef2 ? "success" : "warning";
        }
        return avisChef2 ? "success" : "warning";
    };

    const getSecondButtonLabel = () => {
        if (structure.diff === 2) {
            return avisChef2 ? "Modifier" : "Ajouter";
        }
        return avisChef2 ? "Consulter" : "Non disponible";
    };

    const getThirdButtonColor = () => {
        return avisPeda ? "success" : "warning";
    };

    const getThirdButtonLabel = () => {
        if (isPedaWrite) {
            return avisPeda ? "Modifier mon avis" : "Ajouter mon avis";
        }
        return avisPeda ? "Voir l'avis" : "Avis non disponible";
    };

    const buttonStyle = {
        width: {
            xs: '130px', // 150px for mobile
            md: '180px' // 200px for desktop
        },
        minWidth: {
            xs: '130px', // minimum width of 150px for mobile
            md: '180px' // minimum width of 200px for desktop
        },
        minHeight: '50px', // minimum height
        borderRadius: 2,
        mb: 2 // margin bottom for spacing between buttons
    };

    const renderButton = (text, color, label, onClick) => (
        <Button
            onClick={onClick}
            variant="contained"
            color={color}
            sx={buttonStyle}
            disabled={label.toLowerCase().includes("non disponible")}
        >
            <Box sx={{ textAlign: 'center' }}>
                {text}:<br /> {label}
            </Box>
        </Button>
    );

    let highestLevelFormation = null;
    if (Array.isArray(chef.formations)) {
        const formations = chef.formations.filter(f => f.section_id == structure.section_id);
        if (formations.length > 0) {
            const highestLevelFormationSearch = formations.reduce((max, formation) => {
                return (max.level > formation.level) ? max : formation;
            });
            highestLevelFormation = LevelOptions.find(option =>
                option.level === highestLevelFormationSearch.level &&
                option.section.includes(highestLevelFormationSearch.code)
            );
        }
    }

    return (
        <Card sx={{ marginRight: "13px", marginBottom: 5, p: 2, borderRadius: 2 }}>
            <CardContent sx={{ paddingBottom: '16px !important', paddingLeft: 1, paddingRight: 1}}>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" align="left">
                            {chef.firstname} {chef.lastname} ({getMappedFunction(chef.fonction)})
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" align="left">
                            Plus haut niveau de formation : {highestLevelFormation ? highestLevelFormation.label : "Pas de formation"}
                        </Typography>
                        <Typography variant="h6" align="left">
                           Formation Saint Nicolas : {chef.formation_saint_nicolas ? "Faite " : "à faire"}
                        </Typography>
                    </Grid>

                </Grid>
                <Divider sx={{ my: 2 }} />
                {!chef.sameunite && (
                    <>
                <Grid container alignItems="center" justifyContent="space-between" className='actions-de-formations'>
                    <Grid item>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: "left" }}>
                            Actions
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} justifyContent="space-between" className='actions-de-formations-boutons'>
                            <Grid item>
                                {renderButton(getFirstButtonText(), getFirstButtonColor(), getFirstButtonLabel(),
                                    () => callBacks.openModalAvisCallBack(chef, structure.diff === 1 && allowRules.includes(chefFunction.fonction), avisChef, "1"))}
                            </Grid>
                            {structure.type !== "District" && (
                                <Grid item>
                                    {renderButton(getSecondButtonText(), getSecondButtonColor(), getSecondButtonLabel(),
                                        () => callBacks.openModalAvisCallBack(chef, structure.diff === 2 && allowRules.includes(chefFunction.fonction), avisChef2, "2"))}
                                </Grid>
                            )}
                            <Grid item>
                                {renderButton("Avis de l'ACD", getThirdButtonColor(), getThirdButtonLabel(),
                                    () => callBacks.openModalAvisCallBack(chef, isPedaWrite, avisPeda, "peda"))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        {!chef.sameunite && chef.formationsRegister.length > 0 && (
                            <StructureItemListFormation chef={chef} structure={structure} avisChef={avisChef}
                                loadChef={callBacks.loadChefs} />
                        )}
                    </Grid>
                </Grid>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default StructureItemChef;
