import React, {useEffect, useState} from 'react';
import {

    ListItemText,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Box, Typography, Paper
} from '@mui/material';

import {makeStyles} from 'tss-react/mui';

import {transformFunctionOption, transformFunctionOptionPart} from "../../Utils/functionTransforms";
import {formatStatus} from "../../Utils/Status";
import {useFetchWithToken} from "../../hooks/useFetchWithToken";
import FormFieldView from "../Fiche/FormFieldView";
import ImageSecure from "../Core/ImageSecure";

const useStyles = makeStyles((theme) => ({
    listItem: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    listItemText: {
        fontWeight: 'bold',
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        marginRight: theme.spacing(2),
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
}));

const FormationUser = ({user, index, attachments, mode}) => {
    const classes = useStyles();

    const fetchWithToken = useFetchWithToken();
    const [openFiche, setOpenfiche] = useState(false)
    const [formData, setFormData] = useState();
    const [formState, setFormState] = useState({});
    const [fileNames, setFileNames] = useState({}); // Nouvel état pour stocker les noms des fichiers
    const [loadingPdf, setLoadingPdf] = useState(false);
    const AllowPhoto = ["ListMC"]
    const DisplayFiche = ["ListMC"]
    const DisplayStatus = ["listAdmin"]

    const allowAddStatus  = ["", "WAIT"]
    const handleExportPDF = async () => {
        setLoadingPdf(true);
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${user.formation_user_id}/${user.formation_id}/answer?format=pdf`;

            // Exemple de données à envoyer au backend (formData)
            const formData = {
                userId: user.id,
                userName: `${user.firstName} ${user.lastName}`,
            };

            const blob = await fetchWithToken(apiUrl, "POST", formData, false, "BLOB");


            // Téléchargement du fichier PDF
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.user_firstname} ${user.user_lastname}.pdf`);
            document.body.appendChild(link);
            link.click();
            setLoadingPdf(false);
        } catch (error) {
            console.error('Erreur lors de l\'export en PDF :', error);
            setLoadingPdf(false);
        }
    };
    const loadWishesDetail = async () => {
        try {
            const id = 0;
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/formationsadmin/${user.formation_user_id}/${user.formation_id}/answer`;
            const data = await fetchWithToken(apiUrl);

            var newForm = {
                "sections": []
            }
            let key;
            for (key in data.data) {
                const section = data.data[key]
                newForm.sections.push(section)
            }
            setFormData(newForm);
            setFormState(data.answer)
            for (key in data.answer) {
                const answer = data.answer[key]
                const splits = key.split("_")
                const id_section = splits[0];
                const key_input = splits[1];

                if (newForm.sections[id_section] && newForm.sections[id_section].fields) {
                    let input_map = newForm.sections[id_section].fields.filter(function (f) {
                        return f.name == key_input
                    });
                    if (input_map.length > 0) {
                        input_map = input_map[0];
                        if (input_map.type == "file") {
                            const nameFile = answer.name
                            setFormState(prevState => ({
                                ...prevState,
                                [`${id_section}_${key_input}`]: {content: answer.content, name: nameFile}
                            }));
                            setFileNames(prevState => ({
                                ...prevState,
                                [`${id_section}_${key_input}`]: nameFile // Stocker le nom du fichier dans l'état
                            }));
                        }

                    }

                }

            }

            setOpenfiche(true)
        } catch (error) {
            console.error('Erreur lors du chargement des vœux:', error);
        }
    };

    let  displayAdd= false;
    const handleViewDetails = (userId) => {
        loadWishesDetail();
    };
    const photoAttachment = attachments.find(attachment => attachment.type === 'photo');
    const dataImage = {
        "formation_id": user.formation_id,
        "auth": "mestre",
    }
    const displayPhoto = AllowPhoto.includes(mode)
    const displayFiche = DisplayFiche.includes(mode)
    const displayStatus = DisplayStatus.includes(mode)
    if (displayStatus) {
         displayAdd = allowAddStatus.includes(user.status)
    }
    return (
        <>
            <Grid item xs={12} key={index}>
                <Paper elevation={3} className={classes.listItem}>
                    <Grid container spacing={2} alignItems="center">
                        {displayPhoto && (
                            <Grid item xs={1}>
                                {photoAttachment && (
                                    <ImageSecure
                                        path={photoAttachment.path}
                                        params={dataImage}
                                        style={{width: '100px', height: 'auto', marginBottom: '8px'}}
                                    />
                                )}
                            </Grid>
                        )}
                        <Grid item xs={1}>
                            <ListItemText primary={user ? user.user_code_sycomore : ''}
                                          className={classes.listItemText}/>
                        </Grid>
                        <Grid item xs={2}>
                            <ListItemText primary={user ? user.user_firstname  + " " + user.user_lastname : ''} className={classes.listItemText}/>
                        </Grid>

                        <Grid item xs={3}>
                            <ListItemText
                                primary={user ? transformFunctionOption(user) : ''}
                                className={classes.listItemText}
                            />
                        </Grid>
                        {displayStatus  && (
                            <Grid item xs={3}>
                                <ListItemText
                                    primary={user ? formatStatus(user.status) : ''}
                                    className={classes.listItemText}
                                />
                            </Grid>
                        )}
                        <Grid item xs={2} className={classes.buttonWrapper}>
                            {displayFiche && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleViewDetails(user.formation_user_id)}
                                    className={classes.button}>
                                    Voir la fiche
                                </Button>
                            )}
                            {displayAdd && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleViewDetails(user.formation_user_id)}
                                    className={classes.button}>
                                   Ajouter à la formation
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Dialog open={openFiche} onClose={() => setOpenfiche(false)} maxWidth="lg" fullWidth>
                <DialogTitle>Fiche stagiaire</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {formData &&
                        formData.sections &&
                        formData.sections.map((section, sectionIndex) => (
                            <Box key={sectionIndex} mb={2}>
                                <Typography variant="h6">{section.title}</Typography>
                                <Grid container spacing={2}>
                                    {section.fields.map((field, fieldIndex) => (
                                        <Grid item xs={12} key={fieldIndex}>
                                            <FormFieldView
                                                field={field}
                                                sectionIndex={sectionIndex}
                                                formState={formState}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ))}
                </DialogContent>
                <DialogActions>


                    {(loadingPdf == false) ? (
                        <Button onClick={() => handleExportPDF()} color="primary">
                            Exporter en PDF
                        </Button>
                    ) : <Typography variant="body1" color="textSecondary">
                        Export en cours
                    </Typography>}


                    <Button onClick={() => setOpenfiche(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FormationUser;
