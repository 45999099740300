import React from 'react';
import {
    Typography,
    Box,
    Grid
} from '@mui/material';
import FormField from "./Fiche/FormField";

function MyFicheSection({ formData, formState, handleInputChange, handleCheckboxChange, chefFunction }) {
    return (
        <>
            {formData.sections.map((section, sectionIndex) => {
                if (Array.isArray(section.rules) && section.rules.length > 0) {
                    if (!section.rules.includes(chefFunction.fonction)) {
                        return null;
                    }
                }
                return (
                    <Grid item xs={12} key={section.title} sx={{ m: 1, p: 2 }} className='section-box'>
                        <Typography sx={{ fontFamily: 'Ubuntu', fontWeight: "bold" }} variant="h6">{section.title}</Typography>
                        <Grid className='questions-container'>
                            {section.fields.map(field => (
                                <div className="formField question-box">
                                    {(field.type === 'textarea'
                                        || field.type === 'text'
                                        || field.type === 'checkbox'
                                        || field.type === 'select'
                                    ) && (
                                        <span id='formField-title'>
                                            {field.label}
                                        </span>
                                    )}
                                    <FormField
                                        field={field}
                                        sectionIndex={sectionIndex}
                                        formState={formState}
                                        handleInputChange={handleInputChange}
                                        handleCheckboxChange={handleCheckboxChange}
                                        readOnly={false}
                                        chefFunction={chefFunction}
                                    />
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
}

export default MyFicheSection;
