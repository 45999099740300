import React from 'react';
import {Button, Grid, Typography} from '@mui/material';

const StructureActionFormation = ({register, handleClickOpenValidate, handleClickOpen, chef}) => {
    const {status, validation} = register;

    // Check roles in validation
    const hasRole1 = validation && validation.role1;
    const hasRole2 = validation && validation.role2;
    // Determine what to display based on status and validation
    let content;

    if (status === 'WAIT' || !status) {
        content = <Typography align="center">Info: en liste d'attente</Typography>;
    } else if (status === 'REFUSE' ) {
        content = <Typography align="center">Formation refusée</Typography>;
    }


    else if (status === 'VALIDATE') {
        content = <Typography align="center">Pris en formation</Typography>;
    } else if (status === 'WAIT_VALIDATION') {
        if (!hasRole2) {
            content = (
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={12}>
                        <Button variant="outlined" color="primary" fullWidth
                                onClick={() => handleClickOpenValidate(register)}>
                            Valider la formation
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            variant="outlined"
                            color="error"
                            fullWidth
                            onClick={() => handleClickOpen(register)}
                        >
                            Refuser la formation
                        </Button>
                    </Grid>
                </Grid>
            );
        } else if (!hasRole1) {
            content =
                <Typography align="center">En attente de validation de {chef.firstname} {chef.lastname}</Typography>;
        }
    }

    return (
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {content}
        </Grid>
    );
};

export default StructureActionFormation;
