import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import {Box, Container, Paper, Alert, IconButton, Typography} from "@mui/material";
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FaLinkedin } from 'react-icons/fa';
import Azimuthlogin from "../assets/logo/AZIMUTHlogin.webp";
import logoETN from "../assets/logo-etn.jpg";
import useTexts from "../hooks/useTexte";

import './styles/LoginPage.css';
import {useSystem} from "../Context/System";

const LoginPage = () => {
    const params = new URLSearchParams(window.location.search);
    const { t } = useTexts();
    const {getConfiguration} = useSystem();
    const aideUrl = getConfiguration('URL_AIDE');
    const error = params.get('error');
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || '/';

    const oauthProviders = [
        { name: 'Google ', url: `${process.env.REACT_APP_API_URL}/auth/google?redirect=${from}`, icon: <GoogleIcon color='#FFF' /> },
       // { name: 'Facebook', url: `${process.env.REACT_APP_API_URL}/auth/facebook?redirect=${from}`, icon: <FacebookIcon /> },
        { name: 'LinkedIn', url: `${process.env.REACT_APP_API_URL}/auth/linkedin?redirect=${from}`, icon: <FaLinkedin /> },
        { name: 'Yahoo', url: `${process.env.REACT_APP_API_URL}/auth/yahoo?redirect=${from}` },
        { name: 'Amazon', url: `${process.env.REACT_APP_API_URL}/auth/amazon?redirect=${from}` },


    ];

    const handleOAuthLogin = (provider) => {
        window.location.href = oauthProviders.find(p => p.name === provider).url;
    };

    return (
        <Container maxWidth="sm" className='loginPage-container'>
            <Paper elevation={0}  style={{ backgroundColor: 'transparent'}} className='logo-and-providers-container'>
                <Box sx={{ textAlign: 'center' }} className='logo-and-providers'>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={Azimuthlogin} alt="Azimuth Logo" style={{ width: '300px', }} />
                    </Box>
                    {error && (
                        <Alert severity="error" sx={{ marginTop: 2 }}>
                            {error === '_error_' ? t("ERREUR_TECHNIQUE") : error}
                        </Alert>
                    )}
                    <Box sx={{ textAlign: 'center' }} className='providers'>
                        <p style={{ paddingBottom: '0px', marginBottom: '4px', fontSize: '18px' }}>S'identifier via :</p>
                        {oauthProviders.map(provider => (
                            <div className={`provider-item ${provider.name}`} onClick={() => handleOAuthLogin(provider.name)} key={provider.name}>
                                <IconButton
                                    key={provider.name}
                                    sx={{ margin: 1 }}
                                    color='inherit'
                                    onClick={() => handleOAuthLogin(provider.name)}
                                >
                                    {provider.icon}
                                </IconButton>
                                <p className={`provider-label`}>{provider.name}</p>
                            </div>
                        ))}
                        <a href={aideUrl} target='_blank'>
                            {t('AIDE_CONNEXION')}
                        </a>
                    </Box>
                </Box>
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 4, padding: 2 }}>
                <Typography variant="body2" color="textSecondary" sx={{ marginRight: 1 }}>
                    Réalisation ETN Numérique
                </Typography>
                <img src={logoETN} alt="Logo ETN" style={{ width: '100px' }} />
            </Box>
        </Container>
    );
};

export default LoginPage;
