import React from 'react';
import {

    Typography
} from '@mui/material';


import {branchOptions} from '../../Utils/BranchOption';
import {LevelOptions} from '../../Utils/LevelOption';

import {calculateEndDate, formatDate} from '../../Utils/functionTransforms';
import {formatErrors} from "../../Utils/formatErrors";
import useTexts from "../../hooks/useTexte";

const WishItem = ({wish, index, onSelect}) => {

    const {t} = useTexts()


    console.log(wish)
    const level = LevelOptions.find((branch) => wish.level === branch.key)

    const branch = branchOptions.find((branch) => wish.branch === branch.key)
    const invalideDate = JSON.parse(wish.invalide_data)
    return (
        <>


            <div style={{flex: '0 0 30px', marginBottom: {xs: 2, sm: 0}}}>
                <img src={branch.logo} alt="Training" style={{width: '50px', height: '50px'}}/>
            </div>

            <div style={{flex: '1 1 20%', marginBottom: {xs: 2, sm: 0}}} className={"headlineLevel"}>
                {level.label}
            </div>
            <div style={{flex: '1 1 20%', marginBottom: {xs: 2, sm: 0}}}>
                {wish.user_firstname} {wish.user_lastname}
            </div>
            <div style={{flex: '1 1 20%', marginBottom: {xs: 2, sm: 0}}}>
                {wish.structure_nom}
            </div>
            <div style={{flex: '1 1 20%', marginBottom: {xs: 2, sm: 0}}}>

                <div>du {formatDate(wish.date)}</div>
                <div>au {calculateEndDate(wish.date)}</div>
            </div>


            <div style={{flex: '1 1 40%', alignContent: "center", marginBottom: {xs: 2, sm: 0}}}>
                {invalideDate && invalideDate.length > 0 ? (
                    wish.valide !== 1 ? (
                        <Typography variant="body1" className="requirevalidation">
                            {formatErrors(wish.invalide_data, t)}
                        </Typography>
                    ) : (
                        <Typography variant="body1">Dérogation accordée</Typography>
                    )
                ) : (
                    <Typography variant="body1">Voeu Valide</Typography>
                )}

            </div>


        </>
    );
};

export default WishItem;
